import { useAuth } from "src/utils/AuthProvider";
import { WelcomeScreen } from "../screens/WelcomeScreen";
import { HomeScreen } from "../screens/HomeScreen";

export const TopPage = () => {

  const { isAuth } = useAuth();

  if (!isAuth) return <WelcomeScreen/>

  return <HomeScreen/>
}

