import { Background } from './Background';
import { InputContainer } from '../atoms/InputContainer';
import { useAuth } from 'src/utils/AuthProvider';
import { useCallback, useEffect, useState } from 'react';
import { getDecorationRecord, updateDecorationRecord } from 'src/utils/supabase/decorationUtil';
import { TextInput } from 'react-native';
import { TEMPLATE_COLORS } from 'src/const/colors';
import { useLoading } from 'src/utils/LoadingProvider';

export const CommentEditScreen = () => {

  const { loginUserId } = useAuth();

  const [ decorationRecord, setDecorationRecord ] = useState(null);

  const [ comment, setComment ] = useState(null);

  useEffect(() => {
    if(!loginUserId) return;

    (async () => {
      try {
        const decorationRecord = await getDecorationRecord(loginUserId);

        setDecorationRecord(decorationRecord);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [loginUserId])

  useEffect(() => {
    if (!decorationRecord) return;

    const {
      comment,
    } = decorationRecord;

    setComment(comment);
  }, [decorationRecord]);

  useEffect(() => {
    setDecorationRecord({
      ...decorationRecord,
      comment,
    })
  }, [comment])

  useEffect(() => {
    submit();
  }, [decorationRecord]);

  const submit = useCallback(async () => {
    if(!decorationRecord) return;
    try {
      await updateDecorationRecord(decorationRecord);
    } catch (error) {
      console.error(error);
    }
  }, [decorationRecord]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'CommentEditScreen::decorationRecord': !decorationRecord }), [decorationRecord]);

  if(!comment) return;

  return (
    <Background>
      <InputContainer
        title="マイラムネカードのコメント"
        containerStyle={{
          flex: 1,
        }}
      >
        <TextInput
          multiline={true}
          value={comment}
          onChangeText={(text) => { setComment(text); }}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
            padding: 16,
          }}
        />
      </InputContainer>
    </Background>
  )
}