import { useCallback, useRef, useState } from 'react';

import {
  Text,
} from 'react-native-elements'

import { TEMPLATE_COLORS } from 'src/const/colors';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Icon } from '../atoms/Icon';
import Swipeable from 'react-native-gesture-handler/Swipeable';

import { TRADE_STATUS, TRADE_STATUS_COLOR_MAP, TRADE_STATUS_STRING_MAP, TRADE_STATUS_ICON_ID_MAP } from 'src/const/trade';
import { deleteTradeRecord, upsertTradeRecord } from 'src/utils/supabase/tradeUtils';
import { DeleteIndicator } from '../molecules/DeleteIndicator';
import { ItemRecordCard } from '../molecules/ItemRecordCard';

const swipeableOptions = {
  [TRADE_STATUS.GIVE]: {
    left: {
      tradeStatus: TRADE_STATUS.HOLD,
      text: "取り下げ",
    },
    right: null,
  },
  [TRADE_STATUS.HOLD]: {
    left: {
      tradeStatus: TRADE_STATUS.TAKE,
    },
    right: {
      tradeStatus: TRADE_STATUS.GIVE,
    },
  },
  [TRADE_STATUS.TAKE]: {
    left: null,
    right: {
      tradeStatus: TRADE_STATUS.HOLD,
      text: "ゲット！",
    },
  },
}

const SwipeableBox = (props) => {
  const {
    swipeableOption,
    direction,
  } = props;

  const { tradeStatus, text: _text } =  swipeableOption[direction] || {};

  if(!tradeStatus) return null;

  const color = TRADE_STATUS_COLOR_MAP[tradeStatus];
  const iconId = TRADE_STATUS_ICON_ID_MAP[tradeStatus];
  const text = _text ? _text : TRADE_STATUS_STRING_MAP[tradeStatus];

  return (
    <View
      style={[styles.swipeableBox, {
        backgroundColor: color,
      }]}>
      <Icon
        id="caretLeft"
        color={TEMPLATE_COLORS.INVERSE}
        size={8}
        containerStyle={[styles.caretContainer, { opacity: direction === "left" ? 1 : 0}]}
      />
      <View>
        <Icon id={iconId} color={TEMPLATE_COLORS.INVERSE}/>
        <Text style={{ color: TEMPLATE_COLORS.INVERSE }}>{text}</Text>
      </View>
      {direction === "right"
        ? 
          <Icon
            id="caretRight"
            color={TEMPLATE_COLORS.INVERSE}
            size={8}
            containerStyle={[styles.caretContainer, { opacity: direction === "right" ? 1 : 0}]}
          />
        : <View/>
      }
    </View>
  )
}

export const TradeStatusCaretIcon = (props) => {
  const {
    swipeableOption,
    direction,
  } = props;

  const { tradeStatus } = swipeableOption[direction] || {};

  if (!tradeStatus) return null;

  return (
    <Icon
      id={{
        left: "caretRight",
        right: "caretLeft",
      }[direction]}
      color={TRADE_STATUS_COLOR_MAP[tradeStatus]}
      size={8}
    />
  )
}

export const TradeRecordCard = (props) => {
  const { tradeRecord } = props; 

  const navigation = useNavigation();

  const swipeableRef = useRef(null);

  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ isDeleted, setIsDeleted ] = useState(false);

  const{
    tradeId,
    itemName,
    itemImageFilePath,
    rarity,
    tradeStatus,
    markerId,
  } = tradeRecord;

  const swipeableOption = swipeableOptions[tradeStatus];

  const setTradeStatus = useCallback((tradeStatus) => {
    // ToDo: 更新時に通知を出す
    try{
      upsertTradeRecord({
        ...tradeRecord,
        tradeStatus,
      });
    } catch (error) {
      // ToDo: ユーザーに通知
      console.error(error);
    }
  }, [tradeRecord]);

  const undoRecord = useCallback(() => {
    try {
      upsertTradeRecord(tradeRecord)
    } catch (error) {
      // ToDo: ユーザーに通知
      console.error(error);
    }
  }, [tradeRecord]);

  const deleteRecord = useCallback(() => {
    try {
      deleteTradeRecord(tradeId);
    } catch (error) {
      // ToDo: ユーザーに通知
      console.error(error);
    }
  }, [])

  const isDeleteProcessing = isDeleting || isDeleted;

  return (
    <View>
      <Swipeable
        containerStyle={styles.area}
        renderLeftActions={(progress, dragX) => isDeleteProcessing || <SwipeableBox swipeableOption={swipeableOption} direction="left"/>}
        renderRightActions={(progress, dragX) => isDeleteProcessing || <SwipeableBox swipeableOption={swipeableOption} direction="right"/>}
        onSwipeableOpen={(direction) => {
          if (isDeleteProcessing) {
            swipeableRef.current.close(); // DeleteIndicatorが出ている時は閉じておく
          } else {
            setTradeStatus(swipeableOption[direction].tradeStatus);
          }
        }}
        onSwipeableClose={() => isDeleteProcessing || undoRecord()}
        ref={swipeableRef}
      >
        <ItemRecordCard
          itemName={itemName}
          itemImageFilePath={itemImageFilePath}
          rarity={rarity}
          markerId={markerId}
          touchableProps={{
            onPress: () => navigation.navigate("detail", { tradeId }),
            onLongPress: () => setIsDeleting(true),
            onPressOut: () => setIsDeleting(false),
            delayLongPress: 200
          }}
          leftComponent={<TradeStatusCaretIcon swipeableOption={swipeableOption} direction="right"/>}
          rightComponent={<TradeStatusCaretIcon swipeableOption={swipeableOption} direction="left"/>}
        />
      </Swipeable>
      <DeleteIndicator
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
        isDeleting={isDeleting}
        onDelete={deleteRecord}
        onUndo={undoRecord}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  swipeableBox: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  area: {
    width: "100%",
  },
  caretContainer: {
    marginHorizontal: 8,
    alignItems: "center",
  },
})