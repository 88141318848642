import { ActivityIndicator } from "react-native"
import { TEMPLATE_COLORS } from "src/const/colors"
import { useTheme } from "src/utils/ThemeProvider";


export const LoadingIcon = (props) => {
  
  const { themeColor } = useTheme();

  return (
    <ActivityIndicator
      color={themeColor}
      {...props}
    />
  )
}