import { TRADE_STATUS } from "src/const/trade";
import { getItemRecords } from "./supabase/itemUtil";
import { registerTradeRecord } from "./supabase/tradeUtils";
import { updateDecorationRecord } from "./supabase/decorationUtil";

const tagConvertTable = {
  0: 0,
  1: 3,
  2: 2,
  3: 1,
}

const apiUrl = "https://anone.sakura.ne.jp/api.php?screen_name="

export const importRamuneData = async (userRecord) => {

  const {
    userId,
    screenName,
  } = userRecord;

  const url = apiUrl+screenName;

  const itemRecords = await getItemRecords({});

  try{  
    const response = await fetch(url, {
      mode: "cors",
    })
    const data = await response.json();

    console.log("received ramune data", data);

    const rankTable = data.rank;
    const tagTable = data.tag;
    const amountTable = data.amount;

    for (const deprecatedId of data.give) {
      const itemRecord = itemRecords.filter(({ deprecatedId: _deprecatedId }) => deprecatedId === _deprecatedId)[0];

      [...Array(Number(amountTable[deprecatedId]) || 1)].map(() => {
        registerTradeRecord({
          userId,
          itemId: itemRecord.itemId,
          rarity: Number(rankTable[deprecatedId]),
          tradeStatus: TRADE_STATUS.GIVE,
          markerId: tagConvertTable[tagTable[deprecatedId]],
        })
      })
    }
    for (const deprecatedId of data.take) {
      const itemRecord = itemRecords.filter(({ deprecatedId: _deprecatedId }) => deprecatedId === _deprecatedId)[0];

      registerTradeRecord({
        userId,
        itemId: itemRecord.itemId,
        rarity: Number(rankTable[deprecatedId]),
        tradeStatus: TRADE_STATUS.TAKE,
        markerId: tagConvertTable[tagTable[deprecatedId]],
      })
    }
    for (const deprecatedId of data.have) {
      const itemRecord = itemRecords.filter(({ deprecatedId: _deprecatedId }) => deprecatedId === _deprecatedId)[0];

      [...Array(Number(amountTable[deprecatedId]) || 1)].map(() => {
        registerTradeRecord({
          userId,
          itemId: itemRecord.itemId,
          rarity: Number(rankTable[deprecatedId]),
          tradeStatus: TRADE_STATUS.HOLD,
          markerId: tagConvertTable[tagTable[deprecatedId]],
        })
      })
    }

    updateDecorationRecord({
      userId,
      comment: data.memo,
    })
  } catch (error) {
    console.error(error);
  }
}