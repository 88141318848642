import { useCallback, useEffect, useState } from "react"
import { ScrollView } from "react-native-gesture-handler";

import { Background } from './Background';

import { deleteTradeRecord, getTradeRecord, upsertTradeRecord } from "src/utils/supabase/tradeUtils";
import { Button } from "../atoms/Button";
import { TradeStatusRadioButton } from "../molecules/TradeStatusRadioButton";
import { RarityRadioButton } from "../molecules/RarityRadioButton";
import { InputContainer } from "../atoms/InputContainer";
import { useNavigation } from "@react-navigation/native";
import { SubmitButtonContainer } from "../atoms/SubmitButtonContainer";
import { MarkerRadioButton } from "../molecules/MarkerRadioButton";
import { DangerButton } from "../molecules/DangerButton";
import { useLoading } from "src/utils/LoadingProvider";

export const TradeDetailScreen = (props) => {
  const { route } = props;
  const { params } = route;
  const { tradeId } = params;

  const navigation = useNavigation();

  const [ tradeRecord, setTradeRecord ] = useState(null);

  const [ tradeStatus, setTradeStatus ] = useState(0);
  const [ rarity, setRarity ] = useState(0);
  const [ markerId, setMarkerId ] = useState(0);

  useEffect(() => {
    (async () => {
      const _tradeRecord = await getTradeRecord(tradeId);
      setTradeRecord(_tradeRecord);
    })();
  }, [])

  useEffect(() => {
    if (!tradeRecord) return;

    const {
      tradeStatus,
      rarity,
      markerId,
      itemName,
    } = tradeRecord;

    setTradeStatus(tradeStatus);
    setRarity(rarity);
    setMarkerId(markerId);

    navigation.setOptions({
      headerTitle: itemName,
    })
  }, [tradeRecord]);

  const onSubmit = useCallback(async () => {
    try {
      await upsertTradeRecord({
        ...tradeRecord,
        rarity,
        tradeStatus,
        markerId,
      });
    } catch(error) {
      console.error(error)
    }
    navigation.goBack();
  }, [tradeRecord, rarity, tradeStatus, markerId, navigation]);

  const onDelete = useCallback(async () => {
    try {
      await deleteTradeRecord(tradeId);
    } catch(error) {
      console.error(error)
    }
    navigation.goBack();
  }, [tradeId, navigation]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'TradeDetailPage::tradeRecord': !tradeRecord }), [tradeRecord]);

  return (
    <Background>
      <ScrollView>
        <InputContainer title="ステータス">
          <TradeStatusRadioButton
            tradeStatus={tradeStatus}
            setTradeStatus={setTradeStatus}
          />
        </InputContainer>
        <InputContainer title="レアリティ">
          <RarityRadioButton
            rarity={rarity}
            setRarity={setRarity}
          />
        </InputContainer>
        <InputContainer title="マーカー">
          <MarkerRadioButton
            markerId={markerId}
            setMarkerId={setMarkerId}
          />
        </InputContainer>
      </ScrollView>
      <SubmitButtonContainer>
        <Button title="更新" onPress={onSubmit}/>
        <DangerButton title="削除" onPress={onDelete}/>
      </SubmitButtonContainer>
    </Background>
  );
}
