import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "react-native-elements"
import { TEMPLATE_COLORS } from "src/const/colors";
import { Icon } from "../atoms/Icon";
import { useCallback, useEffect, useState } from "react";

export const MatchingCommentCard = (props) => {
  const {
    comment = "",
    isOpen,
    onPress,
  } = props;

  const [ isScrollEnabled, setIsScrollEnabled ] = useState(false);
  const [ scrollViewHeight, setScrollViewHeight ] = useState(0);
  const [ scrollViewContentHeight, setScrollViewContentHeight ] = useState(0);

  const onScrollViewLayout = useCallback((event) => {
    const { height } = event.nativeEvent.layout;
    setScrollViewHeight(height);
  }, [])

  const onScrollViewContentSizeChange = useCallback((contentWidth, contentHeight) => {
    setScrollViewContentHeight(contentHeight);
  }, [scrollViewHeight]);

  useEffect(() => {
    const _scrollEnabled = scrollViewContentHeight > scrollViewHeight;
    setIsScrollEnabled(_scrollEnabled);
  }, [scrollViewContentHeight, scrollViewHeight])

  const lines = comment.split(/\n/);

  const isMultiLine = lines.length > 1

  const ContainerView = isScrollEnabled ? View : TouchableOpacity;

  if (isOpen) return (
    <ContainerView
      style={[styles.container, {
        alignItems: "flex-start",
        maxHeight: "100%",
      }]}    
      onPress={isMultiLine ? onPress : undefined}
    >
      <ScrollView 
        style={styles.scrollView}
        onLayout={onScrollViewLayout}
        onContentSizeChange={onScrollViewContentSizeChange}
      >
        { lines.map((line, index) => {
          return (
            <Text
              style={styles.comment}
              numberOfLines={isOpen || 1}
              key={index}
            >
              {line}
            </Text>
          )
        }) }
      </ScrollView>
      <Icon
        id="chevronUp"
        color={TEMPLATE_COLORS.INVERSE}
        size={12}
        containerStyle={styles.centerChevron}
        onPress={onPress}
      />
    </ContainerView>
  )

  return (
    <TouchableOpacity
      style={[styles.container, styles.singleContainer, {
        alignItems: "center",
      }]}
      onPress={isMultiLine ? onPress : undefined}
    >
      <Text
        style={styles.comment}
        numberOfLines={isOpen || 1}
      >
        {lines[0]}
      </Text>
      { isMultiLine &&
        <Icon id="chevronDown" color={TEMPLATE_COLORS.INVERSE} size={12} style={styles.rightChevron}/>}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
    minHeight: 24,
    justifyContent: "center",
  },
  scrollView: {
    flexGrow: 1,
  },
  singleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  comment: {
    color: TEMPLATE_COLORS.INVERSE,
  },
  centerChevron: {
    marginTop: 8,
    alignSelf: "center",
    width: "100%",
  },
  rightChevron: {
    marginLeft: 8,
  },
});