import { StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";

import { TEMPLATE_COLORS } from "src/const/colors";

export const InputContainer = (props) => {
  const {
    containerStyle,
    title,
    children,
  } = props;

  return (
    <View style={[styles.container, containerStyle]}>
      <Text style={styles.title}>{title}</Text>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
    marginHorizontal: 8,
    padding: 8,
    backgroundColor: TEMPLATE_COLORS.UI,
    borderRadius: 12,
    alignItems: "center",
  },
  title: {
    marginBottom: 16,
    fontWeight: "bold",
  },
});