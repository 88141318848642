import { View, StyleSheet } from 'react-native';
import { ItemImage } from './ItemImage';

export const ItemList = (props) => {
  const {
    itemList = [],
    style,
    isWrap = false,
    isReverse = false,
    size,
  } = props;

  const flexStyle = StyleSheet.create({
    container: {
      flexDirection: isReverse ? "row-reverse" : "row",
      flexWrap: isWrap ? "wrap" : null,
      alignItems: "center",
    }
  })

  return (
    <View style={[style, flexStyle.container]}>
      {itemList.map(({itemImageFilePath, markerId}, index) => 
        <ItemImage
          itemImageFilePath={itemImageFilePath}
          markerId={markerId}
          size={size}
          key={index}
        />
      )}
    </View>
  );
};