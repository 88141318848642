// アイコンは以下から探す
// https://oblador.github.io/react-native-vector-icons/

import {
  Icon as ElementsIcon,
} from "react-native-elements";

import { TEMPLATE_COLORS } from "src/const/colors";

const definedIconProps = {
  twitter: { name: "twitter", type: "font-awesome-5", color: TEMPLATE_COLORS.TWITTER, },
  heart: { name: "heart", type: "font-awesome-5", color: TEMPLATE_COLORS.HEART, },
  fillHeart: { name: "heart", type: "font-awesome-5", solid: true, color: TEMPLATE_COLORS.HEART, },

  comment: { name: "comment-dots", type: "font-awesome-5", },
  chevronDown: { name: "chevron-down", type: "entypo", },
  chevronUp: { name: "chevron-up", type: "entypo", },

  profile: { name: "bulletin-board", type: "material-community" },
  matching: { name: "heart-half-full", type: "material-community" },
  edit: { name: "square-edit-outline", type: "material-community", },
  design: { name: "palette", type: "material-community", },
  database: { name: "database", type: "material-community", },
  setting: { name: "cog-outline", type: "material-community", },
  developer: { name: "screwdriver", type: "material-community", },
  document: { name: "file-document-multiple", type: "material-community", },

  search: { name: "search", type: "font-isto", },
  exchange: { name: "globe-asia", type: "font-awesome-5", },
  list: { name: "list-ul", type: "font-awesome-5", },

  delete: { name: "delete", type: "ant-design", },

  give: { name: "upload", type: "feather", color: TEMPLATE_COLORS.GIVE,},
  take: { name: "download", type: "feather", color: TEMPLATE_COLORS.TAKE,},
  hold: { name: "box", type: "feather", color: TEMPLATE_COLORS.HOLD,},

  check: { name: "check", type: "font-awesome-5" },
  undo: { name: "undo", type: "font-awesome-5" },
  refresh: { name: "refresh", type: "font-isto" },
  filter: { name: "filter", type: "material-community", },
  filterOff: { name: "filter-off", type: "material-community", },
  newData: { name: "plus", type: "octicon"},
  noImage: { name: "file-image-remove-outline", type: "material-community", },
  noItem: { name: "sad-tear", type: "font-awesome-5", },
  admins: { name: "people", type: "Ionicons", },
  expandable: { name: "triangle-down", type: "entypo", },
  horizontalScrollL: { name: "caret-left", type: "font-awesome-5", },
  horizontalScrollR: { name: "caret-right", type: "font-awesome-5", },
  account: { name: "account", type: "material-community", },
  close: { name: "close", type: "Ionicons" },
  add: { name: "add-circle", type: "MaterialIcons" },
  image: { name: "image-plus", type: "material-community", },
  drawer: { name: "three-bars", type: "octicon", },
  lock: { name: "locked", type: "fontisto" },
  logout: { name: "logout", type: "material-icons" },
  closeUp: { name: "caret-up", type: "font-awesome-5" },
  print: { name: "print", type: "font-awesome-5" },

  rarity5: { name: "roman-numeral-5", type: "material-community", color: TEMPLATE_COLORS.RARITY5 },
  rarity4: { name: "roman-numeral-4", type: "material-community", color: TEMPLATE_COLORS.RARITY4 },
  rarity3: { name: "roman-numeral-3", type: "material-community", color: TEMPLATE_COLORS.RARITY3 },
  rarity2: { name: "roman-numeral-2", type: "material-community", color: TEMPLATE_COLORS.RARITY2 },
  rarity1: { name: "roman-numeral-1", type: "material-community", color: TEMPLATE_COLORS.RARITY1 },
  
  back: { name: "arrowleft", type: "ant-design", },

  caretLeft: { name: "caret-left", type: "font-awesome-5" },
  caretRight: { name: "caret-right", type: "font-awesome-5" },

  expandLess: { name: "expand-less", type: "material-icons" },
  expandMore: { name: "expand-more", type: "material-icons" },

  chevronRight: { name: "chevron-right", type: "font-awesome-5" },
};

export const Icon = (props) => {
  const { id } = props;

  return (
    <ElementsIcon
      color={TEMPLATE_COLORS.ICON}
      {...definedIconProps[id]}
      {...props}
    />
  )
}