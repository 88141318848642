import { Image, ScrollView, StyleSheet, Text, View } from "react-native"
import { Background } from "./Background"
import { useFonts } from 'expo-font';
import { TEMPLATE_COLORS } from "src/const/colors";
import { TwitterLoginButton } from "../molecules/TwitterLoginButton";
import { CheckBox } from "react-native-elements";
import { SubmitButtonContainer } from "../atoms/SubmitButtonContainer";
import { useState } from "react";
import { useAuth } from "src/utils/AuthProvider";
import { AText } from "../atoms/AText";
import { Line } from "../atoms/Line";
import { useNavigation } from "@react-navigation/native";
import { TopHeader } from "../atoms/TopHeader";

export const WelcomeScreen = () => {

  const { isAuth } = useAuth();

  const navigation = useNavigation();

  const [ isAgreed, setIsAgreed ] = useState(false);

  return (
    <Background>
      <ScrollView style={{ width: "100%" }} contentContainerStyle={{ alignItems: "center" }}>
        <TopHeader/>
        <View style={styles.bodyContainer}>
          <Text style={styles.body}>あなたが持ってる、あまったモノ。</Text>
          <Text style={styles.body}>だれかが待ってる、たりないモノ。</Text>
        </View>
        <View style={styles.bodyContainer}>
          <Text style={styles.body}>キモチと一緒に、交換しよう。</Text>
        </View>
      </ScrollView>
      {!isAuth &&
        <SubmitButtonContainer>
          <Line>
            <CheckBox
              checked={isAgreed}
              onPress={() => setIsAgreed(!isAgreed)}
              containerStyle={{backgroundColor: TEMPLATE_COLORS.TRANSPARENT}}
            />
            <AText
              onPress={() => navigation.navigate('document', { screen: 'termOfUse' })}
            >
              利用規約
            </AText>
            <Text> / </Text>
            <AText
              onPress={() => navigation.navigate('document', { screen: 'privacyPolicy' })}
            >
              プライバシーポリシー
            </AText>
            <Text>に同意</Text>
          </Line>
          <TwitterLoginButton disabled={!isAgreed}/>
        </SubmitButtonContainer>
      }
    </Background>
  )
}

const styles = StyleSheet.create({
  bodyContainer: {
    margin: 16,
    padding: 16,
  },
  body: {
    fontSize: 24,
    marginVertical: 8,
    fontFamily: "tegaki",
  },
});