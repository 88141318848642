import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from "react-native-elements"
import { Background } from "./Background"
import { Icon } from "../atoms/Icon"
import { TEMPLATE_COLORS } from "src/const/colors"
import { useNavigation } from "@react-navigation/native"
import { TopHeader } from "../atoms/TopHeader"
import { useTheme } from "src/utils/ThemeProvider"

const PageEntry = (props) => {
  const {
    name,
    title,
    iconId,
    color,
    navigationOption,
    size = 32,
  } = props;

  const navigation = useNavigation();

  return (
    <View
      style={[styles.pageEntryContainer, {
        width: size*3,
      }]}
    >
      <TouchableOpacity
        style={[styles.pageEntryIcon, {
          backgroundColor: color,
          width: size*2,
          height: size*2,
        }]}
        onPress={() => {
          navigation.navigate(name, navigationOption);
        }}
      >
        <Icon
          size={size}
          id={iconId}
          color={TEMPLATE_COLORS.INVERSE}
        />
      </TouchableOpacity>
      <Text style={styles.pageEntryText}>{ title }</Text>
    </View>
  )
}

export const HomeScreen = (props) => {
  const { themeColor } = useTheme();

  return (
    <Background>
      <ScrollView style={styles.scrollView}>
        <TopHeader/>
        <View style={styles.container}>
          <PageEntry
            name="profile"
            title="プロフィール"
            iconId="profile"
            size={48}
            color={themeColor}
          />
          <PageEntry
            name="matching"
            title="マッチング"
            iconId="matching"
            size={48}
            color={TEMPLATE_COLORS.MATCHING}
          />
        </View>
        <View style={styles.container}>
          <PageEntry
            name="edit"
            title="エディタ"
            iconId="edit"
            color={TEMPLATE_COLORS.EDIT}
          />
          <PageEntry
            name="design"
            title="デザイン"
            iconId="design"
            color={TEMPLATE_COLORS.DESIGN}
          />
          <PageEntry
            name="description"
            title="募集設定"
            iconId="comment"
            color={TEMPLATE_COLORS.DESCRIPTION}
          />
        </View>
        <View style={styles.container}>
          <PageEntry
            name="database"
            title="データベース"
            iconId="database"
            color={TEMPLATE_COLORS.DATABASE}
          />
          <PageEntry
            name="document"
            title="使い方"
            iconId="document"
            color={TEMPLATE_COLORS.DOCUMENT}
          />
          <PageEntry
            name="setting"
            title="設定"
            iconId="setting"
            color={TEMPLATE_COLORS.SETTING}
          />
        </View>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
  },
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginVertical: 12,
  },
  pageEntryContainer: {
    alignItems: 'center',
  },
  pageEntryIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  pageEntryText: {
    marginTop: 4,
  },
});