import { StyleSheet } from "react-native";
import { Input } from "react-native-elements";
import { Icon } from "./Icon";
import { useTheme } from "src/utils/ThemeProvider";

export const ItemNameInput = (props) => {
  const { itemName, setItemName } = props;

  const { themeColor } = useTheme();

  return (
    <Input
      placeholder='アイテム名'
      value={itemName}
      onChangeText={(text) => { setItemName(text); }}
      leftIcon={() => <Icon id='search'/>}
      style={styles.main}
      inputStyle={styles.input}
      inputContainerStyle={styles.inputContainer}
      containerStyle={[styles.container, {
        borderColor: themeColor,
      }]}
      errorStyle={styles.error}
    />
  )
}

const styles = StyleSheet.create({
  main: {
    padding: 0,
    margin: 0,
  },
  input: {
    fontSize: 12,
    paddingHorizontal: 12,
  },
  inputContainer: {
    borderBottomWidth: 0,
    margin: 0,
  },
  container: {
    padding: 0,
    margin: 0,
    borderWidth: 2,
    borderRadius: 4,
  },
  error: {
    padding: 0,
    margin: 0,
  },
});