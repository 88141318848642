import { View } from "react-native";
import { RarityIcon } from "./RarityIcon";

const RARITY_LIST = [5, 4, 3, 2, 1];

export const RarityRadioButton = (props) => {
  const {
    rarity,
    setRarity,
  } = props;

  return (
    <View style={{flexDirection: "row"}}>
      {
        RARITY_LIST.map((_rarity) => {
          return (
            <RarityIcon
              rarity={_rarity}
              isSelected={rarity === _rarity}
              onPress={() => {
                setRarity(_rarity);
              }}
              key={_rarity}
            />
          )
        })
      }
    </View>
  );
};