import { Button as ButtonRNE } from "react-native-elements"
import { TEMPLATE_COLORS } from "src/const/colors"
import { useTheme } from "src/utils/ThemeProvider";

export const Button = (props) => {
  const {
    buttonStyle,
    containerStyle,
    titleStyle,
  } = props;

  const { themeColor } = useTheme();

  return (
    <ButtonRNE
      {...props}
      buttonStyle={{
        backgroundColor: themeColor,
        ...buttonStyle,
      }}
      containerStyle={{
        marginVertical: 4,
        marginHorizontal: 16,
        alignSelf: "stretch",
        ...containerStyle,
      }}
      titleStyle={{
        color: TEMPLATE_COLORS.INVERSE,
        marginHorizontal: 8,
        ...titleStyle,
      }}
    />
  )
}