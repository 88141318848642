import { createStackNavigator } from '@react-navigation/stack';
import { TEMPLATE_COLORS } from 'src/const/colors';

import { ItemDetailScreen } from 'src/components/screens/ItemDetailScreen';
import { ItemCreateScreen } from 'src/components/screens/ItemCreateScreen';
import { ItemListScreen } from 'src/components/screens/ItemListScreen';

const Stack = createStackNavigator();

export const DataBasePage = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
      }}
    >
      <Stack.Screen
        name="list"
        component={ItemListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="detail"
        component={ItemDetailScreen}
        options={{
          title: "アイテム詳細",
          headerStyle: {
            backgroundColor: TEMPLATE_COLORS.UI
          }
        }}
      />
      <Stack.Screen
        name="create"
        component={ItemCreateScreen}
        options={{
          title: "アイテム新規登録",
          headerStyle: {
            backgroundColor: TEMPLATE_COLORS.UI
          }
        }}
      />
    </Stack.Navigator>
  );
}