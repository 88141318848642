import { Children } from "react";
import { StyleSheet, View } from "react-native";

export const BalloonContainer = (props) => {
  const { color, children, containerStyle, contentStyle } = props;

    const colorStyle = {
      backgroundColor: color,
    }

    return (
    <View style={containerStyle}>
      <View style={styles.arrowContainer}>
        <View style={[styles.arrow, colorStyle]}/>
      </View>
      <View style={[styles.area, contentStyle, colorStyle]}>
        { children }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  arrowContainer: {
    alignItems: "center",
    height: 0,
  },
  arrow: {
    width: 24,
    height: 24,
    transform: [
      { translateY: -12 },
      { rotate: "45deg" },
    ],
  },
  area: {
    flex: 1,
  },
});