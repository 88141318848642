import { ScrollView, StyleSheet } from "react-native"
import { Background } from "./Background";
import { Text } from "react-native-elements";
import { PrivacyPolicy } from "src/documents/PrivacyPolicy";

export const PrivacyPolicyScreen = () => {
  return (
    <Background>
      <ScrollView style={styles.scrollView}>
        <Text h4>Webサービス「マイラムネ+」プラバシーポリシー</Text>
        <Text>{ PrivacyPolicy }</Text>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    padding: 8,
  },
});