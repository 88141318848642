import {
  View,
  StyleSheet,
} from "react-native"
import { ServiceIcon } from "src/components/atoms/ServiceIcon"

export const ListEndIcon = () => {
  return (
    <View style={styles.container}>
      <ServiceIcon size={18}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    alignItems: "center",
  },
})