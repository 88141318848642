import * as React from "react";
import { Image, StyleSheet, Text } from "react-native"
import { View } from "react-native"
import { TEMPLATE_COLORS } from "src/const/colors";
const background = require('assets/top_background.png')

export const TopHeader = React.memo((props) => {
  return (
    <View style={{ width: "100%" }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
      <View style={styles.titleContainer}>
        <Text style={styles.title}>モノと、</Text>
        <Text style={styles.title}>キモチを</Text>
        <Text style={styles.title}>交換しよう</Text>
      </View>
    </View>
  )
});

const styles = StyleSheet.create({
  titleContainer: {
    margin: 16,
    marginTop: 64,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  title: {
    fontSize: 48,
    fontFamily: "tegaki",
    color: TEMPLATE_COLORS.INVERSE,
  },
});