import { Icon } from "src/components/atoms/Icon";
import { Button } from "src/components/atoms/Button";

import { TEMPLATE_COLORS } from "src/const/colors";

import { signInWithTwitter } from 'src/utils/AuthProvider';
import { View } from "react-native";
import { Input } from "react-native-elements";
import { useState } from "react";


// export const TwitterLoginButton = (props) => {
//   const {
//     iconProps
//   } = props;

//   const [ password, setPassWord ] = useState("");

//   return (
//     <View style={{ width: "100%", marginVertical: 8 }}>
//       <Input
//         value={password}
//         onChangeText={value => setPassWord(value)}
//         label="テストユーザーパス"
//         containerStyle={{ backgroundColor: "#FFFFFF" }}
//       />
//       <Button
//         {...props}
//         icon={ <Icon id="twitter" color={TEMPLATE_COLORS.INVERSE} containerStyle={{marginRight: 12}} {...iconProps}/>}
//         title="Twitterでログイン"
//         onPress={() => {
//           if (password === "test-anone") signInWithTwitter();
//         }}
//       />
//     </View>
//   );
// }


export const TwitterLoginButton = (props) => {
  const {
    iconProps
  } = props;

  return (
    <Button
      {...props}
      icon={ <Icon id="twitter" color={TEMPLATE_COLORS.INVERSE} containerStyle={{marginRight: 12}} {...iconProps}/>}
      title="Twitterでログイン"
      onPress={signInWithTwitter}
    />
  );
}