import { useMemo, useState, useEffect } from "react"
import { View, StyleSheet, TouchableOpacity } from "react-native"
import { Input, Text } from "react-native-elements"

import { Tag } from "./Tag"
import { Icon } from "src/components/atoms/Icon"

import { searchTagList } from "src/utils/supabase/tagUtil"
import { TEMPLATE_COLORS } from "src/const/colors"
import { useTheme } from "src/utils/ThemeProvider"

export const TagPicker = (props) => {
  const {
    isSuggestInputDirect = false,
    tags,
    setTags,
  } = props;

  const { themeColor } = useTheme();

  const [ isOpen, setIsOpen ] = useState(false);

  const [ searchText, setSearchText ] = useState("");
  const [ suggestFilterTags, setSuggestFilterTags ] = useState([]);

  const addTag = (tag) => {
    setTags((_tags) => {
      const updatedTags = Array.from(new Set([..._tags, tag]))
      return updatedTags;
    })
  }

  const removeTag = (tag) => {
    setTags((_tags) => {
      const updatedTags = _tags.filter((_tag) => _tag !== tag);
      return updatedTags;
    })
  }

  const tagComponents = useMemo(() => {
    return tags.map((tag) => {
      return (
        <Tag
          name={tag}
          isClosable={true}
          key={tag}
          onPress={() => {removeTag(tag)}}
        />
      )
    })
  }, [tags])

  useEffect(() => {
    (async() => {
      const originTags = [];
      if (isSuggestInputDirect && searchText !== "") originTags.push(searchText);
      const serverTagList = await searchTagList(searchText);
      const serverTagSet = Array.from(new Set(originTags.concat(serverTagList)))
      const _suggestFilteredTags = serverTagSet.filter((tag) => !tags.includes(tag))
      setSuggestFilterTags(_suggestFilteredTags); 
    })();
  }, [searchText, tags])

  return (
    <TouchableOpacity
      onPress={() => { setIsOpen(true); }}
      style={[styles.container, {
        borderColor: themeColor,
      }]}
    >{
      isOpen
        ?
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Input
            placeholder="タグ名"
            value={searchText}
            leftIcon={<Icon id="search" size={12}/>}
            onChangeText={setSearchText}
            inputStyle={styles.input}
            containerStyle={styles.inputContainer}
            autoFocus={true}
            inputContainerStyle={{
              borderBottomWidth: 0,
            }}
          />
          <Icon
            id="closeUp"
            style={styles.rightIcon}
            onPress={() => { setIsOpen(false); }}
          />
        </View>
        <View style={styles.tagContainer}>
          {suggestFilterTags.map((tag) => {
            return (
              <Tag
                name={tag}
                key={tag}
                onPress={() => {
                  addTag(tag);
                  setIsOpen(false);
                }}
              />
            )
          })}
          {
            (searchText.length >0 && suggestFilterTags.length === 0) &&
              <Text>タグ名が見つかりませんでした。</Text>
          }
        </View>
      </View>
      :
      <>
        <View style={[styles.tagContainer]}>
          {tagComponents}
          {tagComponents.length === 0 && <Text style={styles.placeHolder}>タグを追加</Text>}
        </View>
        <Icon id="add" style={styles.rightIcon}/>
      </>
  }
  </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: "row", 
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 4,
    paddingVertical: 8,
  },
  tagContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    // flex: 1,
    paddingHorizontal: 8,
  },
  input: {
    fontSize: 12,
    paddingHorizontal: 12,
  },
  inputContainer: {
    marginVertical: 2,
    flex: 1,
  },
  placeHolder: {
    fontSize: 12,
    color: TEMPLATE_COLORS.UI_TEXT,
    paddingHorizontal: 18,
  },
  rightIcon: {
    marginRight: 8,
  },
});