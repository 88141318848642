import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Avatar, Text } from "react-native-elements";
import { jumpTwitter } from "src/utils/linkUtil";
import { TEMPLATE_COLORS } from "src/const/colors";

export const MatchingNameCard = (props) => {
  const {
    userRecord,
    onPress,
  } = props;

  if(!userRecord) return null;
  const {
    screenName,
    displayName,
    photoUrl,
    authority,
  } = userRecord;

  return (
    <TouchableOpacity
      onPress={onPress}
      onLongPress={() => {jumpTwitter(screenName);}}
      style={styles.area}
    >
      <Avatar
        rounded
        size={64}
        source={{ uri: photoUrl }}
      />
      <Text
        style={styles.name}
        numberOfLines={1}
      >
        {displayName}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  area: {
    width: "100%",
    alignItems: "center",
    backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
    paddingTop: 16,
    paddingBottom: 32,
    paddingHorizontal: 24,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameAt: {
    fontSize: 18,
  },
  name: {
    fontSize: 24,
  },
})