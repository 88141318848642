export const COLORS = {
  WHITE: "#FFFFFF",
  SUB_WHITE: "#FAFAFA",
  CLOUD: "#ecf0f1",
  GRAY: "#7f8c8d",

  PINK: "#db3490",

  RED: "#db3f34",

  YELLOW: "#dbbc34",

  BLUE: "#45a2ff",
  SKY_BLUE: "#40c8ed",
  PALE_GREEN: "#3be3db",
  SEA_GREEN: "#39dba5",
  GREEN: "#39db72",

  SHADOW: "#959da580",
  TRANSPARENT: "#00000000",

  HIGH_RED: "#FF0000",
  HIGH_GREEN: "#00FF00",
  HIGH_BLUE: "#0000FF",
}

export const TEMPLATE_COLORS = {
  THEME: COLORS.BLUE,

  RARITY5: COLORS.BLUE,
  RARITY4: COLORS.SKY_BLUE,
  RARITY3: COLORS.PALE_GREEN,
  RARITY2: COLORS.SEA_GREEN,
  RARITY1: COLORS.GREEN,

  DANGER: COLORS.RED,

  GIVE: COLORS.BLUE,
  TAKE:  COLORS.GREEN,
  HOLD: COLORS.PALE_GREEN,

  GRADATION1: COLORS.BLUE,
  GRADATION2: COLORS.SKY_BLUE,
  
  BACKGROUND: COLORS.WHITE,
  BACKGROUND_SHADOW: COLORS.CLOUD,

  CARD_BACKGROUND: COLORS.WHITE,
  HIGHLIGHTED_BACKGROUND: COLORS.SUB_WHITE,

  UI: COLORS.SUB_WHITE,
  UI_TEXT: COLORS.GRAY,
  UI_SHADOW: COLORS.SHADOW,

  BORDER: COLORS.CLOUD,
  BOX: COLORS.BLUE,

  ICON: COLORS.GRAY,
  INVERSE: COLORS.WHITE,
  H_TEXT: COLORS.GRAY,

  WARNING: COLORS.YELLOW,
  ERROR: COLORS.RED,
  INFO: COLORS.BLUE,

  TWITTER: COLORS.BLUE,
  HEART: COLORS.PINK,

  MARKER_RED: COLORS.HIGH_RED,
  MARKER_GREEN: COLORS.HIGH_GREEN,
  MARKER_BLUE: COLORS.HIGH_BLUE,

  TRANSPARENT: COLORS.TRANSPARENT,

  MATCHING: COLORS.PINK,
  EDIT: COLORS.SKY_BLUE,
  DESIGN: COLORS.PALE_GREEN,
  DESCRIPTION: COLORS.SEA_GREEN,
  DATABASE: COLORS.GREEN,
  DOCUMENT: COLORS.GRAY,
  SETTING: COLORS.GRAY,
}

export const BOX_SHADOWS = {
  BOTTOM: `0px 3px 3px ${TEMPLATE_COLORS.UI_SHADOW}`,
  TOP: `0px -3px 3px ${TEMPLATE_COLORS.UI_SHADOW}`,
  BOTTOM_LEFT: `-3px -3px 3px ${TEMPLATE_COLORS.UI_SHADOW}`,
  WHOLE: `0px 3px 6px ${TEMPLATE_COLORS.UI_SHADOW}`,
  RIGHT: `3px 0px 3px ${TEMPLATE_COLORS.UI_SHADOW}`,
  LEFT: `-3px 0px 3px ${TEMPLATE_COLORS.UI_SHADOW}`,
}