import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { DrawerContent } from './DrawerContent';
import { MatchingPage } from '../pages/MatchingPage';
import { TradePage } from '../pages/TradePage';
import { DesignPage } from '../pages/DesignPage';
import { CommentPage } from '../pages/CommentPage';
import { DataBasePage } from '../pages/DataBasePage';
import { DeveloperPage } from '../pages/DeveloperPage';
import { Header } from "./Header";
import { ProfilePage } from "../pages/ProfilePage";
import { SettingPage } from "../pages/SettingPage";
import { TopPage } from "../pages/TopPage";
import { AUTHORITY, useAuth } from "src/utils/AuthProvider";
import { DocumentPage } from '../pages/DocumentPage';
import { View } from 'react-native';

const DrawerNavigator = createDrawerNavigator();

export const RootStackScreen = () => {

  const { isAuth, loginUser } = useAuth();
  const { authority } = loginUser || {};

  const linking = {
    prefixes: [
      /* your linking prefixes */
    ],
    config: {
    },
  };

  return (
    // Drawerが見えてしまうバグ対策
    <View style={{ overflow: 'hidden', width: '100%', height: '100%', }}>
      <NavigationContainer linking={linking}>
        <DrawerNavigator.Navigator
          drawerContent={() => <DrawerContent/>}
          initialRouteName={isAuth ? "profile" : "top"}
          screenOptions={{
            header: (props) => <Header {...props}/>,
            drawerType: "front",
            drawerPosition: 'right',
          }}
        >
          <DrawerNavigator.Screen
            name="top"
            component={TopPage}
            options={{
              title: "マイラムネ+",
            }}
          /> 
          <DrawerNavigator.Screen
            name="profile"
            component={ProfilePage}
            options={{
              title: "マイラムネ",
            }}
          />
          { isAuth && <> 
            <DrawerNavigator.Screen
              name="matching"
              component={MatchingPage}
              options={{
                title: "マッチング",
              }}
            />
            <DrawerNavigator.Screen
              name="edit"
              component={TradePage}
              options={{
                title: "エディタ",
              }}
            />
            <DrawerNavigator.Screen
              name="design"
              component={DesignPage}
              options={{
                title: "デザイン",
              }}
            />
            <DrawerNavigator.Screen
              name="description"
              component={CommentPage}
              options={{
                title: "募集設定",
              }}
            />
            <DrawerNavigator.Screen
              name="database"
              component={DataBasePage}
              options={{
                title: "データベース",
              }}
            />
            <DrawerNavigator.Screen
              name="setting"
              component={SettingPage}
              options={{
                title: "設定",
              }}
            />
            { authority >= AUTHORITY.DEVELOPER &&
              <DrawerNavigator.Screen
                name="developer"
                component={DeveloperPage}
                options={{
                  title: "開発者ツール",
                }}
              />
            }
          </>
          }
          <DrawerNavigator.Screen
            name="document"
            component={DocumentPage}
            options={{
              title: "使い方",
            }}
          />
        </DrawerNavigator.Navigator>
      </NavigationContainer>
    </View>
  );
};