import { StyleSheet, TouchableOpacity, View } from "react-native";
import { RARITY_COLOR_MAP } from "src/const/rarity";
import { ItemImage } from "./ItemImage";
import { ListItem, Text } from "react-native-elements";
import { TEMPLATE_COLORS } from "src/const/colors";
import { useMemo } from "react";
import { TRADE_STATUS, TRADE_STATUS_ICON_ID_MAP } from "src/const/trade";
import { Icon } from "../atoms/Icon";
import { checkItemIdExist } from "src/utils/supabase/tradeUtils";

export const ItemRecordCard = (props) => {
  const {
    itemId,
    itemName,
    itemImageFilePath,
    rarity,
    markerId,
    touchableProps,
    leftComponent,
    rightComponent,
    tradeRecords,
  } = props;

  const hasItemId = useMemo(() => checkItemIdExist(tradeRecords, itemId), [tradeRecords]);

  return (
    <TouchableOpacity
      style={[styles.container, {
        borderLeftColor: RARITY_COLOR_MAP[rarity]
      }]}
      {...touchableProps}
    > 
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={styles.sideIcon}>{ leftComponent }</View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ItemImage
            itemImageFilePath={itemImageFilePath}
            markerId={markerId}
            size={64}
            style={{
              marginRight: 16,
            }}
          />
          <ListItem.Title><Text h4>{itemName}</Text></ListItem.Title>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ justifyContent: "center" }}>
          { hasItemId[TRADE_STATUS.GIVE] && <Icon id={TRADE_STATUS_ICON_ID_MAP[TRADE_STATUS.GIVE]} size={16}/> }
          { hasItemId[TRADE_STATUS.TAKE] && <Icon id={TRADE_STATUS_ICON_ID_MAP[TRADE_STATUS.TAKE]} size={16}/> }
          { hasItemId[TRADE_STATUS.HOLD] && <Icon id={TRADE_STATUS_ICON_ID_MAP[TRADE_STATUS.HOLD]} size={16}/> }
        </View>
        <View style={styles.sideIcon}>{ rightComponent }</View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
    paddingVertical: 8,
    borderLeftWidth: 8,
    borderBottomWidth: 1,
    borderBottomColor: TEMPLATE_COLORS.BORDER,
  },
  sideIcon: {
    minWidth: 16,
    justifyContent: "center",
    alignItems: "center",
  },
})