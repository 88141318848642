import { TRADE_STATUS_COLOR_MAP, TRADE_STATUS_STRING_MAP, TRADE_STATUS_ICON_ID_MAP } from "src/const/trade";

import { Icon } from "src/components/atoms/Icon";

import { TEMPLATE_COLORS } from "src/const/colors";
import { SquareButton } from "./SquareButton";

export const TradeStatusIcon = (props) => {
  const {
    tradeStatus,
    isSelected = false,
    size = 24,
    onPress,
    containerStyle,
    isTextEnabled = true,
    isBorderEnabled = true,
  } = props;

  const statusColor = TRADE_STATUS_COLOR_MAP[tradeStatus];

  return (
    <SquareButton
      icon={
        <Icon
          id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}
          size={size}
          color={statusColor}
        />
      }
      selectedIcon={
        <Icon
          id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}
          size={size}
          color={TEMPLATE_COLORS.INVERSE}
        />
      }
      title={TRADE_STATUS_STRING_MAP[tradeStatus]}
      color={statusColor}
      isSelected={isSelected}
      onPress={onPress}
      containerStyle={containerStyle}
      isTextEnabled={isTextEnabled}
      isBorderEnabled={isBorderEnabled}
    />
  );
}