import { Text, View } from "react-native";
import { StyleSheet } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";

export const InverseContainer = (props) => {
  const {
    children,
    title,
    header,
    footer,
    containerStyle,
    headerContainerStyle,
    footerContainerStyle,
  } = props;

  return (
    <View style={[styles.container, containerStyle]}>
      { header && <View style={[styles.header, headerContainerStyle]}>{header}</View>}
      { title && <Text style={styles.title}>{title}</Text>}
      { children }
      { footer && <View style={[styles.footer, footerContainerStyle]}>{footer}</View>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    marginHorizontal: 16,
    padding: 8,
    backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
    borderRadius: 8,
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  title: {
    fontWeight: "bold",
    marginBottom: 12,
  },
  footer: {
    flexDirection: "row",
    marginTop: 12,
  },
});