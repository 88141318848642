import { Background } from "../screens/Background";
import { createStackNavigator } from "@react-navigation/stack";
import { TermOfUseScreen } from "../screens/TermOfUseScreen";
import { DocumentTopScreen } from "../screens/DocumentTopScreen";
import { PrivacyPolicyScreen } from "../screens/PrivacyPolicyScreen";

const Stack = createStackNavigator();

export const DocumentPage = () => {
  return (
    <Background>
      <Stack.Navigator
        initialRouteName="top"
        screenOptions={{
          headerShown: false,
          animationEnabled: true,
        }}
      >
        <Stack.Screen
          name="top"
          component={DocumentTopScreen}
        />
        <Stack.Screen
          name="termOfUse"
          component={TermOfUseScreen}
        />
        <Stack.Screen
          name="privacyPolicy"
          component={PrivacyPolicyScreen}
        />
      </Stack.Navigator>
    </Background>
  )
}