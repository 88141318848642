import { FilterInputContainer } from '../atoms/FilterInputContainer';
import { RarityFilter } from '../molecules/RarityFilter';
import { TagPicker } from '../molecules/TagPicker';
import { ItemNameInput } from '../atoms/ItemNameInput';
import { Text, View } from 'react-native';
import { Icon } from '../atoms/Icon';
import { useState } from 'react';
import { TEMPLATE_COLORS } from 'src/const/colors';

export const FilterView= (props) => {
  const {
    isInitialExpanded = false,
    isExpandable = true,
    hasBorder = false,
    itemName,
    setItemName,
    rarities,
    setRarities,
    tags,
    setTags,
  } = props;

  const [isExpanded, setIsExpanded] = useState(isInitialExpanded);

  return(
    <View style={[
      hasBorder && {
        borderBottomWidth: 1,
        borderColor: TEMPLATE_COLORS.BORDER,
      }
    ]}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FilterInputContainer>
          <ItemNameInput
            itemName={itemName}
            setItemName={setItemName}
          />
        </FilterInputContainer>
        {isExpandable && !isExpanded && 
          <Icon
            id='expandMore'
            onPress={() => setIsExpanded(!isExpanded)}
          />
        }
      </View>
      { isExpanded &&
        <>
          <FilterInputContainer>
            <TagPicker
              defaultIsOpen={false}
              tags={tags}
              setTags={setTags}
            />
          </FilterInputContainer>
          <FilterInputContainer>
            <RarityFilter
              rarities={rarities}
              setRarities={setRarities}
            />
          </FilterInputContainer>
        </>
      }
      {isExpandable && isExpanded && 
        <Icon
          id='expandLess'
          onPress={() => setIsExpanded(!isExpanded)}
        />
      }
    </View>
  );
}