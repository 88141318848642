import { RootStackScreen } from './src/components/navigations';
import { AuthProvider, useAuth } from 'src/utils/AuthProvider';
import { useFonts } from 'expo-font';
import { LoadingOverlay, LoadingProvider, useLoading } from 'src/utils/LoadingProvider';
import { StyleSheet, View } from 'react-native';
import { useEffect } from 'react';
import { ThemeProvider } from 'src/utils/ThemeProvider';

const tegakiFont = require('assets/fonts/AP.ttf');

export default function App() {

  return (
    <AuthProvider>
      <LoadingProvider>
        <ThemeProvider>
          <Main/>
        </ThemeProvider>
      </LoadingProvider>
    </AuthProvider>
  );
}

const Main = (props) => {

  const { isLoading, isInitializing } = useAuth();

  const [isFontLoaded] = useFonts({
    tegaki: tegakiFont,
  });

  const { setLoading } = useLoading()
  useEffect(() => setLoading({ 'Main::Auth': isLoading }), [isLoading])
  useEffect(() => setLoading({ 'Main::Auth::initialize': isInitializing }), [isInitializing])
  // useEffect(() => setLoading({ 'Main::Font': !isFontLoaded }), [isFontLoaded])

  return (
    <View style={styles.container}>
      <RootStackScreen/>
      <LoadingOverlay/>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});