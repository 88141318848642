import { useState, useEffect, useMemo } from 'react';
import {
  FlatList,
} from 'react-native';

import { ListEndIcon } from '../molecules/ListEndIcon';
import { CreateButton } from '../molecules/CreateButton';
import { ItemRecordCard } from '../molecules/ItemRecordCard';

import { Background } from './Background';

import { EmptyItemScreen } from './EmptyItemScreen';

import { useAuth } from 'src/utils/AuthProvider';
import { getItemRecords } from 'src/utils/supabase/itemUtil';
import { getTradeRecords } from 'src/utils/supabase/tradeUtils';
import { FilterView } from '../organisms/FilterView';
import { useLoading } from 'src/utils/LoadingProvider';

export const ItemListScreen = (props) => {
  const { navigation } = props;

  const { loginUserId } = useAuth();

  const [ itemRecords, setItemRecords ] = useState(null);
  const [ tradeRecords, setTradeRecords ] = useState(null);

  const [filterItemName, setFilterItemName] = useState("");
  const [filterRarities, setFilterRarities] = useState([1, 2, 3, 4, 5,]);
  const [filterTags, setFilterTags] = useState([]);

  const refreshItemRecords = async () => {
    try {
      const itemRecords = await getItemRecords({
        itemName: filterItemName,
        rarities: filterRarities,
        tags: filterTags,
      });
      setItemRecords(itemRecords);
    } catch(error) {
      console.error(error);
    }
  }

  useEffect(() => {
    refreshItemRecords();
  }, [filterItemName, filterRarities, filterTags]);

  useEffect(() => {
    (async() => {
      try{
        if(!loginUserId) return;

        const tradeRecords = await getTradeRecords({ userId: loginUserId });
        setTradeRecords(tradeRecords);
      } catch (error) {
        console.error(error);
      }
    })()
  }, [loginUserId])

  const [ screenWidth, setScreenWidth ] = useState(1);
  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setScreenWidth(width);
  }

  const numColumns = Math.round(screenWidth / 480) || 1;

  const ListBody = useMemo(() => {
    return (
      <FlatList
        data={itemRecords}
        keyExtractor={itemRecord => itemRecord.itemId}
        renderItem={({item: itemRecord}) => {
          const {
            itemId,
            itemName,
            itemImageFilePath,
            defaultRarity,
          } = itemRecord;
          return (
            <ItemRecordCard
              itemId={itemId}
              itemName={itemName}
              itemImageFilePath={itemImageFilePath}
              rarity={defaultRarity}
              touchableProps={{
                onPress: () => {
                  navigation.navigate("detail", {
                    itemId,
                  })
                }
              }}
              tradeRecords={tradeRecords}
              key={itemRecord.itemId}
            />
          )
        }}
        numColumns={numColumns}
        key={numColumns} // numColumnsを変える場合はkeyも変えないといけないらしい
        ListFooterComponent={<ListEndIcon/>}
      />
    );
  }, [itemRecords, tradeRecords])

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'ItemListScreen::itemRecords': !itemRecords }), [itemRecords]);
  useEffect(() => setLoading({ 'ItemListScreen::tradeRecords': !tradeRecords }), [tradeRecords]);

  if(!itemRecords) return null;

  return (
    <Background
      onLayout={onLayout}
    >
      <FilterView
        hasBorder={true}
        itemName={filterItemName}
        setItemName={setFilterItemName}
        rarities={filterRarities}
        setRarities={setFilterRarities}
        tags={filterTags}
        setTags={setFilterTags}
      />
      {(() => {
        if(itemRecords.length === 0) return <EmptyItemScreen/>

        return ListBody
      })()}
      {/* <CreateButton
        onPress={() => { navigation.navigate("create"); }}
      /> */}
    </Background>
  )
}