import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  FlatList, StyleSheet, View,
} from 'react-native';

import { ListEndIcon } from '../molecules/ListEndIcon';
import { ItemRecordCard } from '../molecules/ItemRecordCard';

import { Background } from './Background';

import { EmptyItemScreen } from './EmptyItemScreen';

import { useAuth } from 'src/utils/AuthProvider';
import { getItemRecord, getItemRecords } from 'src/utils/supabase/itemUtil';
import { Icon } from '../atoms/Icon';
import { TEMPLATE_COLORS } from 'src/const/colors';
import { CheckBox } from 'react-native-elements';
import { Button } from '../atoms/Button';
import { TRADE_STATUS_COLOR_MAP, TRADE_STATUS_ICON_ID_MAP, TRADE_STATUS_DESCRIPTION_MAP, TRADE_STATUS_STRING_MAP } from 'src/const/trade';
import { getTradeRecords, registerTradeRecord, } from 'src/utils/supabase/tradeUtils';
import { FilterView } from '../organisms/FilterView';
import { useLoading } from 'src/utils/LoadingProvider';

export const TradeAddScreen = (props) => {
  const { navigation, route } = props;
  const { params } = route;
  const { tradeStatus } = params;

  const { loginUserId } = useAuth();
  const { setLoading } = useLoading();

  const [ checkedItemIdList, setCheckedItemIdList ] = useState([]);

  const [ itemRecords, setItemRecords ] = useState(null);
  const [ tradeRecords, setTradeRecords ] = useState(null);

  const [filterItemName, setFilterItemName] = useState("");
  const [filterRarities, setFilterRarities] = useState([1, 2, 3, 4, 5,]);
  const [filterTags, setFilterTags] = useState([]);

  const refreshItemRecords = async () => {
    try {
      const _itemRecords = await getItemRecords({
        itemName: filterItemName,
        rarities: filterRarities,
        tags: filterTags,
      });
      setItemRecords(_itemRecords);
    } catch(error) {
      console.error(error);
    }
  }

  useEffect(() => {
    refreshItemRecords();
  }, [filterItemName, filterRarities, filterTags]);

  useEffect(() => {
    (async() => {
      try{
        if(!loginUserId) return;

        const tradeRecords = await getTradeRecords({ userId: loginUserId });
        setTradeRecords(tradeRecords);
      } catch (error) {
        console.error(error);
      }
    })()
  }, [loginUserId])

  useEffect(() => {
    if (!tradeStatus) return;

    navigation.setOptions({
      headerTitle: `${TRADE_STATUS_DESCRIPTION_MAP[tradeStatus]}を選択`,
    });
  }, [tradeStatus]);

  const addTradeRecords = useCallback(async () => {
    setLoading({"TradeAddScreen::addTradeRecords": true});
    try{
      for (const itemId of checkedItemIdList) {
        const { defaultRarity } = await getItemRecord(itemId);
        await registerTradeRecord({
          itemId,
          rarity: defaultRarity,
          tradeStatus,
          userId: loginUserId,
        })
      }
      navigation.goBack();
    } catch (error) {
      console.error(error);
    }
    setLoading({"TradeAddScreen::addTradeRecords": false});
  }, [checkedItemIdList, loginUserId, setLoading])

  const [ screenWidth, setScreenWidth ] = useState(1);
  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setScreenWidth(width);
  }

  const numColumns = Math.round(screenWidth / 480) || 1;

  const ListBody = useMemo(() => {
    return (
      <FlatList
        data={itemRecords}
        keyExtractor={itemRecord => itemRecord.itemId}
        renderItem={({item: itemRecord}) => {
          const {
            itemId,
            itemName,
            itemImageFilePath,
            defaultRarity,
          } = itemRecord;

          const isChecked = checkedItemIdList.includes(itemId);

          return (
            <ItemRecordCard
              itemId={itemId}
              itemName={itemName}
              itemImageFilePath={itemImageFilePath}
              rarity={defaultRarity}
              rightComponent={
                <CheckBox
                  checked={isChecked}
                  checkedColor={TRADE_STATUS_COLOR_MAP[tradeStatus]}
                  pointerEvents='none'
                />
              }
              tradeRecords={tradeRecords}
              touchableProps={{
                onPress: () => {
                  const _list = isChecked
                    ? checkedItemIdList.filter(_itemId => itemId !== _itemId)
                    : [...checkedItemIdList, itemId]
                  setCheckedItemIdList(_list);
                }
              }}
              key={itemRecord.itemId}
            />
          )
        }}
        numColumns={numColumns}
        key={numColumns} // numColumnsを変える場合はkeyも変えないといけないらしい
        ListFooterComponent={<ListEndIcon/>}
      />
    );
  }, [itemRecords, checkedItemIdList])

  useEffect(() => setLoading({ 'TradeAddScreen::itemRecords': !itemRecords }), [itemRecords]);
  useEffect(() => setLoading({ 'TradeAddScreen::tradeRecords': !tradeRecords }), [tradeRecords]);

  if(!itemRecords) return null;

  return (
    <Background
      onLayout={onLayout}
    >
      <FilterView
        hasBorder={true}
        itemName={filterItemName}
        setItemName={setFilterItemName}
        rarities={filterRarities}
        setRarities={setFilterRarities}
        tags={filterTags}
        setTags={setFilterTags}
      />
      {(() => {
        if(itemRecords.length === 0) return <EmptyItemScreen/>

        return ListBody
      })()}
      <Button
        icon={
          <Icon
            id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}
            color={checkedItemIdList.length ? TEMPLATE_COLORS.INVERSE : TEMPLATE_COLORS.ICON}
          />
        }
        title={`${TRADE_STATUS_STRING_MAP[tradeStatus]}を追加`}
        buttonStyle={{
          backgroundColor: TRADE_STATUS_COLOR_MAP[tradeStatus],
        }}
        containerStyle={styles.buttonContainer}
        disabled={!checkedItemIdList.length}
        onPress={addTradeRecords}
      />
    </Background>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    padding: 8,
  },
})