import { StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";

import { TEMPLATE_COLORS } from "src/const/colors";

export const InfoContainer = (props) => {
  const {
    title,
    subTitle,
    children,
  } = props;

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={{marginBottom: 6}}>
          <Text style={styles.titleText}>{title}</Text>
        </View>
        <View style={styles.borderHorizontal}/>
        <View style={{marginBottom: 6, alignSelf: "flex-end",}}>
          <Text style={styles.subTitleText}>{subTitle}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={{ flex: 1 }}>
          {children}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
    marginHorizontal: 16,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: TEMPLATE_COLORS.H_TEXT,
    flex: 1,
  },
  subTitleText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: TEMPLATE_COLORS.H_TEXT,
    flex: 1,
  },
  borderHorizontal: {
    height: 2,
    flex: 1,
    backgroundColor: TEMPLATE_COLORS.BORDER,
    marginLeft: 16,
  },
  contentContainer: {
    flexDirection: "row",
  },
})