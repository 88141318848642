import { useState, useEffect, useCallback, useRef } from 'react';
import {
  FlatList, StyleSheet, View,
} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { TRADE_STATUS, TRADE_STATUS_COLOR_MAP, TRADE_STATUS_STRING_MAP, TRADE_STATUS_ICON_ID_MAP, TRADE_STATUS_URL_MAP } from 'src/const/trade';

import { ListEndIcon } from '../molecules/ListEndIcon';
import { TradeRecordCard } from '../organisms/TradeRecordCard';

import { Background } from './Background';

import { EmptyItemScreen } from './EmptyItemScreen';
import { useAuth } from 'src/utils/AuthProvider';

import { getTradeRecords } from 'src/utils/supabase/tradeUtils';
import { Icon } from '../atoms/Icon';
import { TEMPLATE_COLORS } from 'src/const/colors';
import { useNavigation } from '@react-navigation/native';
import { FAB } from 'react-native-elements';
import { FilterView } from '../organisms/FilterView';
import { useLoading } from 'src/utils/LoadingProvider';

const Tab = createMaterialTopTabNavigator();

const getTabScreenOption = (tradeStatus) => {
  return {
    tabBarIcon: () => <Icon id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}/>,
    title: TRADE_STATUS_STRING_MAP[tradeStatus],
    tabBarLabelStyle: {
      color: TRADE_STATUS_COLOR_MAP[tradeStatus],
    },
    tabBarIndicatorStyle: {
      backgroundColor: TRADE_STATUS_COLOR_MAP[tradeStatus],
      height: 4,
      borderRadius: 2,
    },
  }
}

const TradeRecordsList = (props) => {
  const { tradeRecords, tradeStatus } = props;

  const navigation = useNavigation();

  return (
    <View style={styles.listContainer}>
      {
        (tradeRecords && tradeRecords.length > 0) ?
          <FlatList
            data={tradeRecords}
            keyExtractor={tradeRecord => tradeRecord.tradeId}
            renderItem={({item: tradeRecord}) => {
              return (
                <TradeRecordCard
                  tradeRecord={tradeRecord}
                  key={tradeRecord.tradeId}
                />
              )
            }}
            ListFooterComponent={
              <View style={{ paddingBottom: 32 }}>
                <ListEndIcon/>
              </View>
            }
          />
        :
          <EmptyItemScreen/>
      }
      <FAB
        icon={
          <Icon
            id="add"
            size={24} 
            color={TEMPLATE_COLORS.INVERSE}
            containerStyle={{ marginRight: 4 }}
          />
        }
        title={
          <Icon
            id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}
            size={24} 
            color={TEMPLATE_COLORS.INVERSE}
          />
        }
        color={TRADE_STATUS_COLOR_MAP[tradeStatus]}
        containerStyle={styles.addButton}
        onPress={() => {
          navigation.navigate("add", {
            tradeStatus,
          });
        }}
      />
    </View>
  )
};

export const TradeListScreen = () => {
  const { loginUserId } = useAuth();

  const [ tradeRecords, setTradeRecords] = useState(null);

  const [filterItemName, setFilterItemName] = useState("");
  const [filterRarities, setFilterRarities] = useState([1, 2, 3, 4, 5,]);
  const [filterTags, setFilterTags] = useState([]);

  const navigation = useNavigation();

  const refreshTradeRecords = useCallback(async () => {
    if (!loginUserId) return;

    try {
      const tradeRecords = await getTradeRecords({
        userId: loginUserId,
        itemName: filterItemName,
        rarities: filterRarities,
        tags: filterTags,
      });
      setTradeRecords(tradeRecords);
    } catch(error) {
      console.error(error);
    }
  }, [loginUserId, filterItemName, filterRarities, filterTags]);

  const initialize = useCallback(() => {
    navigation.getParent()?.setOptions({
      headerRightIcons: [
        {
          id: "refresh",
          onPress: refreshTradeRecords,
        },
      ],
    });
    
    refreshTradeRecords();
  }, [refreshTradeRecords])

  useEffect(() => {
    initialize();
    return navigation.addListener('focus', initialize);
  }, [navigation, initialize]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'TradeListPage::tradeRecords': !tradeRecords }), [tradeRecords]);

  if(!tradeRecords) return null;

  return (
    <Background>
      <FilterView
        hasBorder={true}
        itemName={filterItemName}
        setItemName={setFilterItemName}
        rarities={filterRarities}
        setRarities={setFilterRarities}
        tags={filterTags}
        setTags={setFilterTags}
      />
      <Tab.Navigator
        initialRouteName='hold'
        screenOptions={{
          title: "",
          swipeEnabled: false,
        }}
      >
        <Tab.Screen
          name={TRADE_STATUS_URL_MAP[TRADE_STATUS.GIVE]}
          options={getTabScreenOption(TRADE_STATUS.GIVE)}
        >
          {() => <TradeRecordsList tradeRecords={tradeRecords[TRADE_STATUS.GIVE]} tradeStatus={TRADE_STATUS.GIVE}/>}
        </Tab.Screen>
        <Tab.Screen
          name={TRADE_STATUS_URL_MAP[TRADE_STATUS.HOLD]}
          options={getTabScreenOption(TRADE_STATUS.HOLD)}
        >
          {() => <TradeRecordsList tradeRecords={tradeRecords[TRADE_STATUS.HOLD]} tradeStatus={TRADE_STATUS.HOLD}/>}
        </Tab.Screen>
        <Tab.Screen
          name={TRADE_STATUS_URL_MAP[TRADE_STATUS.TAKE]}
          options={getTabScreenOption(TRADE_STATUS.TAKE)}
          >
            {() => <TradeRecordsList tradeRecords={tradeRecords[TRADE_STATUS.TAKE]} tradeStatus={TRADE_STATUS.TAKE}/>}
          </Tab.Screen>
      </Tab.Navigator>
    </Background>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    width: "100%",
    height: "100%",
  },
  addButton: {
    position: "absolute",
    bottom: 12,
    right: 8,
  },
});