import { View } from "react-native";

import { TradeStatusIcon } from "./TradeStatusIcon";

import { TRADE_STATUS } from "src/const/trade";

const TRADE_STATUS_LIST = [
  TRADE_STATUS.GIVE,
  TRADE_STATUS.HOLD,
  TRADE_STATUS.TAKE,
];

export const TradeStatusRadioButton = (props) => {
  const {
    tradeStatus,
    setTradeStatus,
    size,
  } = props;

  return (
    <View style={{flexDirection: "row"}}>
      {
        TRADE_STATUS_LIST.map((_tradeStatus) => {
          return (
            <TradeStatusIcon
              tradeStatus={_tradeStatus}
              isSelected={tradeStatus === _tradeStatus}
              onPress={() => {
                setTradeStatus(_tradeStatus);
              }}
              size={size}
              key={_tradeStatus}
            />
          )
        })
      }
    </View>
  );
};