import { useMemo } from "react";
import {
  Image,
} from "react-native-elements"

const Icon = require("assets/icon.png");

export const ServiceIcon = (props) => {
  const {
    size=32,
  } = props;

  const source = Icon;

  return (
    <Image
      {...props}
      source={ source }
      style={{ width: size, height: size }}
    />
  )
}