import { ScrollView, StyleSheet } from "react-native"
import { TermOfUse } from "src/documents/TermOfUse"
import { Background } from "./Background";
import { Text } from "react-native-elements";

export const TermOfUseScreen = () => {
  return (
    <Background>
      <ScrollView style={styles.scrollView}>
        <Text h4>Webサービス「マイラムネ+」利用規約</Text>
        <Text>{ TermOfUse }</Text>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    padding: 8,
  },
});