import { View } from "react-native";

import { RarityIcon } from "./RarityIcon";

const FILTER_RARITY_LIST = [
  5,
  4,
  3,
  2,
  1,
];

export const RarityFilter = (props) => {
  const {
    rarities,
    setRarities,
  } = props;

  return (
    <View style={{flexDirection: "row"}}>
      {
        FILTER_RARITY_LIST.map((rarity) => {
          const isSelected = rarities.includes(rarity);
          return (
            <RarityIcon
              rarity={rarity}
              isSelected={isSelected}
              onPress={() => {
                let _rarities = [...rarities];
                if(isSelected){
                  _rarities = _rarities.filter(r => r !== rarity);
                } else {
                  _rarities.push(rarity);
                }
                setRarities(_rarities);
              }}
              isTextEnabled={false}
              key={rarity}
            />
          )
        })
      }
    </View>
  );
};