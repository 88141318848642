import { useState } from "react";
import { View } from "react-native";
import { CheckBox } from "react-native-elements";
import { TEMPLATE_COLORS } from "src/const/colors";
import { Button } from "../atoms/Button";

export const  DangerButton = (props) => {
  const {
    onPress,
    title,
  } = props;

  const [ isConfirmed, setIsConfirmed ] = useState(false);

  return (
    <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "stretch" }}>
      <CheckBox
        containerStyle={{backgroundColor: TEMPLATE_COLORS.TRANSPARENT}}
        checkedColor={TEMPLATE_COLORS.DANGER}
        checked={isConfirmed}
        onPress={() => setIsConfirmed(!isConfirmed)}
      />
      <Button
        title={title}
        disabled={!isConfirmed}
        buttonStyle={{
          backgroundColor: TEMPLATE_COLORS.DANGER,
        }}
        containerStyle={{
          flex: 1,
        }}
        onPress={() => {
          onPress();
          setIsConfirmed(false);
        }}
      />
    </View>
  );
}