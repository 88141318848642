// https://github.com/react-native-image-picker/react-native-image-picker
import {
  View,
} from 'react-native';
import { Image} from 'react-native-elements';
import * as ExpoImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { Icon } from 'src/components/atoms/Icon';
import { TouchableOpacity } from 'react-native-gesture-handler';
 
const IMAGE_SHAPE = {
  width: 63,
  height: 88,
}

const imageLibraryOptions = {
  mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
  allowsEditing: true,
  aspect: [4, 3],
  quality: 1,
  ...IMAGE_SHAPE,
};

export const ItemImagePicker = (props) => {
  const {
    size,
    image,
    setImage,
  }  = props;

  const getImage = async () => {
    setImage(null);
    // @ts-expect-error uri
    const pickerResult = await ExpoImagePicker.launchImageLibraryAsync(imageLibraryOptions); 
    const manipulateResult = await manipulateAsync(
      // @ts-expect-error uri
      pickerResult.uri,
      [{ resize: IMAGE_SHAPE },],
      { compress: 0.5, format: SaveFormat.JPEG }
    );
    const blobImage = await fetch(manipulateResult.uri).then(string => string.blob())
    setImage(blobImage);
  }

  return (
    <View style={{ alignItems: "center" }}>
      <TouchableOpacity
        onPress={getImage}
      >
        { image
          ? <Image source={{ uri: URL.createObjectURL(image)}} style={IMAGE_SHAPE}/>
          : <Icon id="image" size={size}/>
        }
      </TouchableOpacity>
    </View>
  )
}
