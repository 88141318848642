import { useCallback, useState } from "react";
import { Text, TouchableOpacity } from "react-native";

import { TEMPLATE_COLORS } from "src/const/colors";

export const SquareButton = (props) => {
  const {
    icon,
    selectedIcon,
    title,
    color,
    isSelected = false,
    onPress,
    containerStyle,
    isTextEnabled = true,
    isBorderEnabled = true,
  } = props;

  const backgroundColor = isSelected ? color : TEMPLATE_COLORS.INVERSE;
  const textColor = isSelected ? TEMPLATE_COLORS.INVERSE : color;

  return (
    <TouchableOpacity
      style={{
        width: 64,
        height: 64,
        paddingVertical: 8,
        marginHorizontal: 4,
        backgroundColor,
        borderRadius: 4,
        justifyContent: "center",
        alignItems: "center",
        borderColor: color,
        borderWidth: isBorderEnabled ? 2 : null,
        ...containerStyle,
      }}
      onPress={onPress}
    >
      { selectedIcon ? (isSelected ? selectedIcon : icon) : icon }
      {isTextEnabled &&
        <Text
          style={{
            color: textColor,
            fontSize: 10,
            flex: 1,
          }}
        >
          { title }
        </Text>
      }
    </TouchableOpacity>
  );
}