import { useCallback, useEffect, useState } from "react";
import { BalloonContainer } from "../atoms/BalloonContainer";
import { MatchingNameCard } from "../organisms/MatchingNameCard";
import { Background } from "../screens/Background"
import { getUserRecord } from "src/utils/supabase/userUtil";
import { useAuth } from "src/utils/AuthProvider";
import { getDecorationRecord } from "src/utils/supabase/decorationUtil";
import { getTemplateThemeColor } from "src/const/template";
import { Linking, ScrollView, StyleSheet, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { TradesCard } from "../organisms/TradesCard";
import { getTradablesWith, getTradeRecords } from "src/utils/supabase/tradeUtils";
import { Icon } from "../atoms/Icon";
import { TRADE_STATUS, TRADE_STATUS_ICON_ID_MAP, TRADE_STATUS_STRING_MAP, TRADE_STATUS_URL_MAP } from "src/const/trade";
import { InverseContainer } from "../atoms/InverseContainer";
import { TradableCard } from "../organisms/TradableCard";
import { SERVICE_URL, jumpTwitter } from "src/utils/linkUtil";
import { TEMPLATE_COLORS } from "src/const/colors";
import { SubmitButtonContainer } from "../atoms/SubmitButtonContainer";
import { TwitterLoginButton } from "../molecules/TwitterLoginButton";
import { PrintComponent } from "../atoms/PrintComponent";
import { useLoading } from "src/utils/LoadingProvider";
import { FilterView } from "../organisms/FilterView";

const TradeHeader = (props) => {
  const { tradeStatus, count } = props;

  return (
    <>
      <Icon id={TRADE_STATUS_ICON_ID_MAP[tradeStatus]}/>
      <Text
        style={{
          marginLeft: 8,
          fontWeight: "bold",
        }}
      >
        {TRADE_STATUS_STRING_MAP[tradeStatus]}
      </Text>
      <Text
        style={{
          marginLeft: 8,
        }}
      >
        ({count})
      </Text>
    </>
  )
}

export const ProfilePage = (props) => {
  const { route } = props;
  const { params, path } = route;
  const { userId } = params || {};

  const { loginUserId, isAuth, isNotLogged, isFailed } = useAuth();

  const navigation = useNavigation();

  const [ userRecord, setUserRecord ] = useState(null);
  const [ decorationRecord, setDecorationRecord ] = useState(null);
  const [ tradeRecords, setTradeRecords ] = useState(null);

  const [ tradables, setTradables ] = useState(null);

  const [filterItemName, setFilterItemName] = useState("");
  const [filterRarities, setFilterRarities] = useState([1, 2, 3, 4, 5,]);
  const [filterTags, setFilterTags] = useState([]);

  const targetUserId = userId || loginUserId; 
  const isSelf = targetUserId === loginUserId;

  // ログインしていないにもかかわらず、userIdが指定されなければトップページへ
  useEffect(() => {
    if (isNotLogged || isFailed) {
      if (!userId) {
        navigation.navigate("top");
      }
    }
  }, [isNotLogged, isFailed, userId, loginUserId])

  const refreshUserRecord = async () => {
    if(!targetUserId) return;

    try{
      const userRecord = await getUserRecord(targetUserId);
      setUserRecord(userRecord);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshDecorationRecord = async () => {
    if(!targetUserId) return;

    try{
      const decorationRecord = await getDecorationRecord(targetUserId);
      setDecorationRecord(decorationRecord);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshTradeRecords = async () => {
    if(!targetUserId) return;

    try{
      const tradeRecords = await getTradeRecords({
        userId: targetUserId,
        itemName: filterItemName,
        rarities: filterRarities,
        tags: filterTags,
      });
      setTradeRecords(tradeRecords);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshTradables = async () => {
    if(!targetUserId) return;
    if(isSelf) return;
    if(!loginUserId) return;

    try{
      const tradables = await getTradablesWith(targetUserId, loginUserId);
      setTradables(tradables);
    } catch (error) {
      console.error(error);
    }
  };

  const initialize = () => {
    setUserRecord(null);
    setDecorationRecord(null);

    refreshUserRecord();
    refreshDecorationRecord();
    refreshTradeRecords();
    refreshTradables();
  };

  useEffect(() => {
    initialize();
    return navigation.addListener('focus', initialize);
  }, [navigation, targetUserId, loginUserId]);

  useEffect(() => {
    refreshTradeRecords();
  }, [ filterItemName, filterRarities, filterTags ]);

  const {
    displayName = "",
    screenName = "",
  } = userRecord || {};

  const {
    themeColorId = 0,
    comment = "",
  } =  decorationRecord || {};

  const themeColor = getTemplateThemeColor(themeColorId);

  useEffect(() => {
    navigation.setOptions({
      backgroundColor: themeColor,
    });
  }, [themeColorId]);

  useEffect(() => {
    navigation.setOptions({
      title: `${displayName}のマイラムネ`,
    });
  }, [displayName])

  useEffect(() => {
    navigation.setOptions({
      headerRightIcons: [
        isSelf || {
          id: "twitter",
          onPress: () => {jumpTwitter(screenName);},
        },
      ],
    });
  }, [screenName]);

  const tweet = useCallback(() => {
    const pageUrl = encodeURIComponent(`${SERVICE_URL}/profile?userId=${targetUserId}`);
    const text = encodeURIComponent(`【マイラムネ+✨】\n${comment.split(/\n/)[0]}\n\n#譲 : ${tradeRecords[TRADE_STATUS.GIVE].length}\n#求 : ${tradeRecords[TRADE_STATUS.TAKE].length}\n#所持 : ${tradeRecords[TRADE_STATUS.HOLD].length}\n\n`)
    const hashtags = encodeURIComponent(`マイラムネ,交換,募集,コレクション`);

    const linkUrl = `https://twitter.com/intent/tweet?text=${text}&url=${pageUrl}&hashtags=${hashtags}`

    Linking.openURL(linkUrl).catch(error => {
      console.error('TweetURLを開けませんでした。', error);
    });
  }, [targetUserId, comment, tradeRecords]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'ProfilePage::userRecord': !userRecord }), [userRecord]);
  useEffect(() => setLoading({ 'ProfilePage::decorationRecord': !decorationRecord }), [decorationRecord]);

  if(!tradeRecords) return null;

  return (
    <Background>
      <ScrollView style={{ flex: 1 }}>
        <MatchingNameCard
          userRecord={userRecord}
        />
        <BalloonContainer contentStyle={styles.body} color={themeColor}>
          { !isSelf &&
            <InverseContainer>
              <Text>{comment}</Text>
            </InverseContainer>
          }
          { !isSelf && tradables &&
            <PrintComponent
              content={
                <TradableCard
                  giveTradeIds={tradables.gives}
                  takeTradeIds={tradables.takes}
                />
              }
              title="あなたと交換できるもの"
              color={themeColor}
            />
          }
          <InverseContainer title="絞り込み">
            <FilterView
              itemName={filterItemName}
              setItemName={setFilterItemName}
              rarities={filterRarities}
              setRarities={setFilterRarities}
              tags={filterTags}
              setTags={setFilterTags}
            />
          </InverseContainer>
          {
            [TRADE_STATUS.GIVE, TRADE_STATUS.TAKE, TRADE_STATUS.HOLD].map((tradeStatus) => 
              <PrintComponent
                content={ <TradesCard tradeRecords={tradeRecords[tradeStatus]}/> }
                title={ <TradeHeader tradeStatus={tradeStatus} count={tradeRecords[tradeStatus].length}/> }
                footer={
                  <Icon
                    id='edit'
                    containerStyle={styles.footerIcon}
                    onPress={() => navigation.navigate(
                      'edit', {
                        screen: 'list',
                        params: { screen: TRADE_STATUS_URL_MAP[tradeStatus] },
                      }
                    )}
                  />
                }
                color={themeColor}
                key={tradeStatus}
              />
            )
          }
        </BalloonContainer>
      </ScrollView>
      { isSelf && isAuth &&
        <Icon
          id="twitter" 
          raised
          reverse
          onPress={tweet}
          containerStyle={styles.tweetButton}
        />
      }
      { (isNotLogged || isFailed) &&
        <SubmitButtonContainer containerStyle={{ backgroundColor: themeColor }}>
          <TwitterLoginButton
            buttonStyle={{ backgroundColor: TEMPLATE_COLORS.INVERSE }}
            titleStyle={{ color: undefined }}
            iconProps={{
              color: TEMPLATE_COLORS.TWITTER,
            }}
          />
        </SubmitButtonContainer>
      }
    </Background>
  )
}

const styles = StyleSheet.create({
  body: {
    paddingVertical: 8,
    paddingBottom: 64,
  },
  tweetButton: {
    position: 'absolute',
    right: 4,
    bottom: 8,
  },
  footerIcon: {
    marginHorizontal: 8,
  },
});