import { StyleSheet, View } from "react-native";

export const Line = (props) => {
  const {
    style,
    children,
  } = props;

  return (
    <View
      {...props}
      style={[styles.text, style]}
    >
      { children }
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});