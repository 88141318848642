import { Chip } from "react-native-elements"

import { Icon } from "src/components/atoms/Icon";

import { TEMPLATE_COLORS } from "src/const/colors"
import { useTheme } from "src/utils/ThemeProvider";

export const Tag = (props) => {
  const {
    name,
    isClosable = false,
    onPress = () => {},
  } = props;

  const { themeColor } = useTheme();

  return (
    <Chip
      title={name}
      type="outline"
      buttonStyle={{
        paddingVertical: 2,
        margin: 2,
        backgroundColor: TEMPLATE_COLORS.INVERSE,
        borderColor: themeColor,
      }}
      titleStyle={{
        color: themeColor,
        fontSize: 12,
      }}
      iconRight={true}
      icon={isClosable ? <Icon id="close" color={themeColor} size={16}/> : null}
      onPress={onPress}
    />
  )
}