import { supabase } from ".";
import uuid from "react-native-uuid";

const getItemRecordFromDBRecord = (DBRecord) => {
  const {
    item_id,
    item_name,
    item_image_file_path,
    default_rarity,
    admin_user_id,
    tags,
    deprecated_id,
  } = DBRecord;

  return {
    itemId: item_id,
    itemName: item_name,
    itemImageFilePath: item_image_file_path,
    defaultRarity: default_rarity,
    adminUserId: admin_user_id,
    tags,
    deprecatedId: deprecated_id,
  }
}
const getDBRecordFromItemRecord = (itemRecord) => {
  const {
    itemId,
    itemName,
    itemImageFilePath,
    defaultRarity,
    adminUserId,
    deprecatedId,
  } = itemRecord;

  return {
    item_id: itemId,
    name: itemName,
    item_image_file_path: itemImageFilePath,
    default_rarity: defaultRarity,
    admin_user_id: adminUserId,
    deprecated_id: deprecatedId,
  }
}

const getDBRecordFromTagRecord = (TagRecord) => {
  const {
    name,
    itemId
  } = TagRecord;

  return {
    name,
    item_id: itemId,
  }
}

export const registerItem = async (params) => {
  const {
    deprecatedId,
    itemName,
    itemImage,
    defaultRarity,
    tags = [],
    adminUserId,
  } = params;

  return false; // ToDo: アイテム登録機能を付けるときに外す

  if (!itemImage) return false;
  if (!itemName) return false;
  if (!adminUserId) return false;
  
  const itemId = uuid.v4();
  const itemImageId = uuid.v4();

  const itemImageFilePath = `${itemImageId}.JPEG`;

  // アイテムレコードアップロード
  const { error: itemInsertError } = await supabase.from('items').insert(getDBRecordFromItemRecord({
    itemId,
    itemName,
    itemImageFilePath,
    defaultRarity,
    adminUserId,
    deprecatedId,
  }))
  if (itemInsertError) console.error("item insert error", itemInsertError);

  // タグデータアップロード
  for (const name of tags){
    const { error: tagInsertError } = await supabase.from('tags').insert(getDBRecordFromTagRecord({
      itemId,
      name,    
    }));
    if(tagInsertError) console.error("tag insert error", tagInsertError);
  }

  // 画像アップロード
  const { error: imageUploadError } = await supabase.storage.from("item-images").upload(itemImageFilePath, itemImage);
  if(imageUploadError) console.error("item image register error:", imageUploadError);

  return true;
}

export const getItemRecords = async (params) => {
  const {
    itemName = "",
    rarities = [1, 2, 3, 4, 5],
    tags = [],
  } = params;

  let query = supabase.from("items_view").select('*')

  query = query.ilike('item_name', `%${itemName}%`)
  query = query.in('default_rarity', rarities)
  query = query.contains("tags", tags);

  query = query.order("item_name")

  const { data, error } = await query;
  if(error) throw(error);

  return data.map(getItemRecordFromDBRecord);
}

export const getItemRecord = async (itemId) => {
  const { data: itemData } = await supabase.from("items_view").select('*').eq("item_id", itemId);

  return getItemRecordFromDBRecord(itemData[0])
}

export const cleanItem = async (itemId) => {
  console.log("Try Item Delete:", itemId)

  try{
    const { data, error: selectItemError } = await supabase.from("items").select('item_image_file_path').eq("item_id", itemId);
    if(selectItemError) throw(selectItemError);

    const itemImageFilePath = data[0].item_image_file_path;

    const { error: itemDeleteError } = await supabase.from("items").delete().eq("item_id", itemId); // アイテムデータの削除
    if(itemDeleteError) throw(itemDeleteError);

    const { error: imageDeleteError } = await supabase.storage.from("item-images").remove([itemImageFilePath]) // 画像の削除
    if (imageDeleteError) throw(imageDeleteError);
    

    console.log("Item Deleted:", itemId)
  } catch (error) {
    console.error(error)
  }
}