import { Text, View } from "react-native";
import { InverseContainer } from "./InverseContainer";
import { ServiceIcon } from "./ServiceIcon";
import domtoimage from "dom-to-image";
import { saveAs } from 'file-saver';
import { useCallback, useRef, useState } from "react";
import { Icon } from "./Icon";
import { useLoading } from "src/utils/LoadingProvider";

const IMAGE_WIDTH = 480;
const IMAGE_SCALE = 4;

const captureComponent = async (component) => {
  const blob = await domtoimage.toBlob(component)
  saveAs(blob, 'my-ramune.png')
};

const CaptureFooter = () => {
  return (
    <View style={{ marginTop:16, alignItems: "center" }}>
      <Text>モノと、キモチを交換しよう。</Text>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <ServiceIcon size={12} containerStyle={{ marginRight: 8, marginTop: 2 }}/>
        <Text style={{ fontWeight: "bold" }}>マイラムネ</Text>
      </View>
    </View>
  )
}

export const PrintComponent = (props) => {
  const {
    content,
    title,
    color,
    footer,
  } = props;

  const [imageWidth, setImageWidth] = useState(1);
  const [imageHeight, setImageHeight] = useState(1);

  const captureRef = useRef(null);

  const { setLoading } = useLoading();

  const onLayout = useCallback((event) => {
    const { width, height } = event.nativeEvent.layout;

    setImageWidth(width);
    setImageHeight(height);
  }, []);

  return (
    <>
      <InverseContainer
        title={title}
        footer={
          <>
            {footer}
            <Icon id="print" onPress={async () => {
              setLoading({ "PrintComponent::print": true });
              await captureComponent(captureRef.current)
              setLoading({ "PrintComponent::print": false });
            }}/>
          </>
        }
        footerContainerStyle={{
          alignSelf: "flex-end",
        }}
      >
        {content}
      </InverseContainer>
      <View
        style={{
          position: "absolute",
          opacity: 0,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
        pointerEvents="none"
      >
        <View
          ref={captureRef}
          style={{
            width: imageWidth * IMAGE_SCALE,
            height: imageHeight * IMAGE_SCALE,
            transform: [
              { translateX: imageWidth * IMAGE_SCALE / 2 },
              { translateY: imageHeight * IMAGE_SCALE / 2 },
            ]
          }}
        >
          <View
            style={{
              width: IMAGE_WIDTH,
              backgroundColor: color,
              transform: [
                { translateX: -imageWidth/2 },
                { translateY: -imageHeight/2 },
                { scale: IMAGE_SCALE },
              ]
            }}
            onLayout={onLayout}
          >
            <InverseContainer title={title}>
              { content }
              <CaptureFooter/>
            </InverseContainer>
          </View>
        </View>
      </View>
    </>
  )
}