import { View } from "react-native";
import { MarkerButton } from "../atoms/MarkerButton";

const MARKER_LIST = [1, 2, 3];

export const MarkerRadioButton = (props) => {
  const {
    markerId,
    setMarkerId,
    size = 48,
  } = props;

  return (
    <View style={{flexDirection: "row"}}>
      {
        MARKER_LIST.map((_markerId) => {
          const isSelected = markerId === _markerId;
          return (
            <MarkerButton
              isSelected={isSelected}
              markerId={_markerId}
              onPress={() => {
                if(isSelected) {
                  setMarkerId(0)
                } else {
                  setMarkerId(_markerId)
                }
              }}
              size={size}
              key={_markerId}
            />
          )
        })
      }
    </View>
  );
};
