import { COLORS } from "./colors";

export const TEMPLATE_THEME_COLORS = {
  blue: COLORS.BLUE,
  skyBlue: COLORS.SKY_BLUE,
  paleGreen: COLORS.PALE_GREEN,
  seaGreen: COLORS.SEA_GREEN,
  green: COLORS.GREEN,
  yellow: COLORS.YELLOW,
  red: COLORS.RED,
  pink: COLORS.PINK,
}

export const getTemplateThemeColor = (themeColorId) => {
  const themeColor = TEMPLATE_THEME_COLORS[themeColorId];
  return themeColor || COLORS.BLUE;
}