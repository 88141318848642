import {
  View,
  StyleSheet,
} from "react-native"
import { Text } from "react-native-elements";
import { Icon } from "../atoms/Icon";
import { Background } from './Background';

export const EmptyItemScreen = (props) => {
  const {
    text = "アイテムが見つかりませんでした…",
  } = props;

  return (
    <Background>
      <View style={styles.container}>
        <Icon id="noItem" size={42}/>
        <Text>{text}</Text>
      </View>
    </Background>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});