import { useState, useEffect } from "react";

import { StyleSheet, View } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";

import { RarityIcon } from "../molecules/RarityIcon";
import { ItemList } from "../molecules/ItemList";

import { getTradeRecordBulk } from "src/utils/supabase/tradeUtils";
import { TradableCardHeader } from "../molecules/TradableCardHeader";

const Divider = (props) => {
  const { color } = props;
  return (
    <View style={[styles.divider, {
      backgroundColor: color,
    }]}/>
  )
}

export const TradableCard = (props) => {
  const {
    giveTradeIds = [],
    takeTradeIds = [],
    isShowHeader = true,
  } = props;
  
  const [ giveTrades, setGiveTrades ] = useState([]);
  const [ takeTrades, setTakeTrades ] = useState([]); 

  useEffect(() => {
    (async () => {
      const tradeItems = await getTradeRecordBulk(giveTradeIds);
      setGiveTrades(tradeItems);
    })()
  }, [giveTradeIds])

  useEffect(() => {
    (async () => {
      const tradeItems = await getTradeRecordBulk(takeTradeIds);
      setTakeTrades(tradeItems);
    })()
  }, [takeTradeIds])


  return (
    <View style={{ width: "100%" }}>
      {isShowHeader && <TradableCardHeader/>}
      {
        [5, 4, 3, 2, 1].map((rarity) => {
          const giveItemList = giveTrades.filter(record => record.rarity === rarity);
          const takeItemList = takeTrades.filter(record => record.rarity === rarity);

          if (giveItemList.length + takeItemList.length === 0) return null;

          return (
            <View style={styles.rowContainer} key={rarity}>
              <View style={ styles.row }>
                <View style={styles.column}>
                    <ItemList itemList={giveItemList} isReverse={true} isWrap={true} size={52} key={rarity}/>
                </View>
                <View style={styles.centerColumn} key={rarity}>
                  <RarityIcon
                    rarity={rarity}
                    isSelected={false}
                    isTextEnabled={false}
                    isBorderEnabled={false}
                  />
                </View>
                <View style={styles.column}>
                  <ItemList itemList={takeItemList} isWrap={true} size={52} key={rarity}/>
                </View>
              </View>
              <Divider/>
            </View>
          )
        })
      }
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    alignItems: "center",
  },
  row: {
    marginVertical: 4,
    width: "100%",
    flexDirection: "row",
  },
  column: {
    flex: 1,
  },
  centerColumn: {
    width: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    width: "90%",
    height: 1,
    backgroundColor: TEMPLATE_COLORS.BORDER,
  }
});