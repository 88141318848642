import { Background } from './Background';
import { InputContainer } from '../atoms/InputContainer';
import { ThemeColorPicker } from '../molecules/ThemeColorPicker';
import { useAuth } from 'src/utils/AuthProvider';
import { useCallback, useEffect, useState } from 'react';
import { getDecorationRecord, updateDecorationRecord } from 'src/utils/supabase/decorationUtil';
import { ScrollView } from 'react-native';
import { useLoading } from 'src/utils/LoadingProvider';
import { useTheme } from 'src/utils/ThemeProvider';

export const DesignScreen = () => {

  const { loginUserId } = useAuth();

  const [ decorationRecord, setDecorationRecord ] = useState(null);

  const [ themeColorId, setThemeColorId ] = useState(null);

  const { setThemeColorId: _setThemeColorId } = useTheme();

  useEffect(() => {
    if(!loginUserId) return;
  
    (async () => {
      try {
        const decorationRecord = await getDecorationRecord(loginUserId);

        setDecorationRecord(decorationRecord);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [loginUserId])

  useEffect(() => {
    if (!decorationRecord) return;

    const {
      themeColorId,
    } = decorationRecord;

    setThemeColorId(themeColorId);
  }, [decorationRecord]);

  useEffect(() => {
    setDecorationRecord({
      ...decorationRecord,
      themeColorId,
    })
    _setThemeColorId(themeColorId);
  }, [themeColorId])

  useEffect(() => {
    submit();
  }, [decorationRecord]);

  const submit = useCallback(async () => {
    if(!decorationRecord) return;
    try {
      await updateDecorationRecord(decorationRecord);
    } catch (error) {
      console.error(error);
    }
  }, [decorationRecord]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'DecorationScreen::decorationRecord': !decorationRecord }), [decorationRecord]);

  return (
    <Background>
      <ScrollView style={{ flex: 1 }}>
        <InputContainer title="テーマカラー">
          <ThemeColorPicker
            colorId={themeColorId}
            setColorId={setThemeColorId}
          />
        </InputContainer>
      </ScrollView>
    </Background>
  )
}