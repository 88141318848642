import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";
import { getDecorationRecord } from "./supabase/decorationUtil";
import { getTemplateThemeColor } from "src/const/template";
import { useLoading } from "./LoadingProvider";

const ThemeContext = createContext(null);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = (props) => {
  const { children } = props;
  
  const { loginUserId } = useAuth();

  const [ themeColorId, setThemeColorId ] = useState(null);

  useEffect(() => {
    refreshThemeColor();
  }, [loginUserId])

  const refreshThemeColor = useCallback(async () => {
    if(!loginUserId) return;
    try {
      const decorationRecord = await getDecorationRecord(loginUserId);
      const { themeColorId } = decorationRecord;

      setThemeColorId(themeColorId);
    } catch (error) {
      console.error(error);
    }
  }, [loginUserId]);

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'ThemeProvider::themeColorId': loginUserId && !themeColorId }), [themeColorId, loginUserId]);

  const value = {
    themeColor: getTemplateThemeColor(themeColorId),
    setThemeColorId,
    refreshThemeColor,
  }

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}