import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FlatList
} from 'react-native';

import { MatchingCard } from '../organisms/MatchingCard';

import { EmptyItemScreen } from './EmptyItemScreen';

import { Background } from './Background';
import { useAuth } from 'src/utils/AuthProvider';
import { getTradables } from 'src/utils/supabase/tradeUtils';
import { PageIndicator } from '../molecules/PageIndicator';
import { useNavigation } from '@react-navigation/native';
import { getDecorationRecord } from 'src/utils/supabase/decorationUtil';
import { getTemplateThemeColor } from 'src/const/template';
import { ERROR_CODES } from 'src/const/errors';
import { getUserRecord } from 'src/utils/supabase/userUtil';
import { useLoading } from 'src/utils/LoadingProvider';

export const MatchingListScreen = () => {

  const { loginUserId } = useAuth();

  const navigation = useNavigation();

  const [ tradables, setTradables ] = useState(null);
  const [ matchingCardData, setMatchingCardData ] = useState(null);

  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  const [pageIndex, setPageIndex] = useState(0); 

  useEffect(() => {
    if(!loginUserId) return;
    (async () => {
      const _tradables= await getTradables(loginUserId);
      setTradables(_tradables);
    })();
  }, [loginUserId]);

  useEffect(() => {
    if(!tradables) return;

    (async () => {
      try {
        const matchingCardData = await Promise.all(
          tradables.map(async ({userId, giveTradeIds, takeTradeIds}) => {
            const userRecord = await getUserRecord(userId);

            let decorationRecord
            try {
              decorationRecord = await getDecorationRecord(userId);
            } catch (error) {
              if (!ERROR_CODES.NO_DECORATION_DATA) throw error;
              console.error(error);
            }
            
            return { userRecord, decorationRecord, giveTradeIds, takeTradeIds };
          })
        )
        console.log("MatchingListScreen::matchingCardData", matchingCardData);
        setMatchingCardData(matchingCardData.slice(0,100));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tradables]);
  
  const onFlatListLayout = useCallback((event) => {
    const { width, height } = event.nativeEvent.layout;
    setPageWidth(width);
    setPageHeight(height);
  }, []);

  const onScroll = useCallback((event) => {
    const { x } = event.nativeEvent.contentOffset;

    const _pageIndex = Math.round(x / pageWidth);
    setPageIndex(_pageIndex);
  }, [pageWidth]);

  const ListBody = useMemo(() => {
    return (
      <FlatList
        data={matchingCardData}
        extraData={[ pageWidth, pageHeight ]}
        horizontal
        renderItem={({ item, index }) => (
          <MatchingCard
            {...item}
            width={pageWidth}
            height={pageHeight}
          />
        )}
        keyExtractor={( item ) => item.userRecord.userId}
        contentContainerStyle={{
          alignItems: 'stretch',
        }}
        onLayout={onFlatListLayout}
        onScroll={onScroll}
        pagingEnabled
        initialNumToRender={100}
        initialScrollIndex={0}
        showsHorizontalScrollIndicator={false}
      />
    );
  }, [matchingCardData, pageWidth, pageHeight]); 

  const pageColor = useMemo(() => {
    return getTemplateThemeColor(matchingCardData?.[pageIndex]?.decorationRecord?.themeColorId);
  }, [pageIndex, matchingCardData]);

  useEffect(() => {
    navigation.setOptions({
      backgroundColor: pageColor,
    });
  }, [pageColor])

  const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'MatchingListScreen::tradables': !tradables }), [tradables]);
  useEffect(() => setLoading({ 'MatchingListScreen::matchingCardData': !matchingCardData }), [matchingCardData]);

  if(!loginUserId) return null;

  if(!matchingCardData) return null;

  return (
    <Background>
      { matchingCardData.length > 0
        ? 
          <>
            { ListBody }
            <PageIndicator
              pageIndex={pageIndex}
              pageLength={matchingCardData.length}
              pageColor={pageColor}
            />
          </>
        : <EmptyItemScreen text="交換できる人はいないようです…"/>
      }
    </Background>
  )
}
