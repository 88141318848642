import { Text } from "react-native";

import { TEMPLATE_COLORS } from "src/const/colors";
import { RARITY_COLOR_MAP, RARITY_ID_MAP,  RARITY_STRING_MAP } from "src/const/rarity";
import { SquareButton } from "./SquareButton";

export const RarityIcon = (props) => {
  const {
    rarity,
    isSelected = false,
    onPress,
    containerStyle,
    isTextEnabled = true,
    isBorderEnabled = true,
  } = props;

  const rarityColor = RARITY_COLOR_MAP[rarity];

  return (
    <SquareButton
      icon={
        <Text
          style={{
            color: rarityColor,
            fontWeight: 'bold',
          }}
        >
          { RARITY_ID_MAP[rarity] }
        </Text>
      }
      selectedIcon={
        <Text
          style={{
            color: TEMPLATE_COLORS.INVERSE,
            fontWeight: 'bold',
          }}
        >
          { RARITY_ID_MAP[rarity] }
        </Text>
      }
      title={RARITY_STRING_MAP[rarity]}
      color={rarityColor}
      isSelected={isSelected}
      onPress={onPress}
      containerStyle={containerStyle}
      isTextEnabled={isTextEnabled}
      isBorderEnabled={isBorderEnabled}
    />
  );
}