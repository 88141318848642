import {
  StyleSheet,
  ScrollView,
} from 'react-native';

import { Tag } from './Tag';

export const TagContainer = (props) => {
  const {
    tags,
  } = props;

  return(  
    <ScrollView
      horizontal={true}
      style={styles.tagContainer}
    >
      { tags?.map((name) => {
        return <Tag name={name} key={name}/>
      }) }
  </ScrollView>
  );
}

const styles = StyleSheet.create({
  tagContainer: {
    paddingBottom: 12,
    marginBottom: -12,
  },
});