import { useNavigation } from "@react-navigation/native";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Divider } from "react-native-elements";

import { Icon } from "../atoms/Icon";
import { LoadingIcon } from "../molecules/LoadingIcon";
import { TwitterLoginButton } from "../molecules/TwitterLoginButton";
import { UserAvatar } from "../atoms/UserAvatar";
import { DescriptionText } from "../atoms/DescriptionText";

import { TEMPLATE_COLORS } from "src/const/colors";

import { AUTHORITY, AUTH_STATES, useAuth, signOut } from "src/utils/AuthProvider";

const MenuItem = (props) => {
  const { isLock, name, title, iconId, navigationOption } = props;

  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={menuStyles.container}
      onPress={() => {
        if (isLock) return;
        // @ts-expect-error navigation
        navigation.navigate(name, navigationOption);
      }}
    >
      <View style={menuStyles.iconContainer}>
        <Icon id={iconId} size={24}/>
      </View>
      <Text style={menuStyles.title}>{title}</Text>
      { isLock && <Icon id="lock" size={24} containerStyle={menuStyles.right}/> }
    </TouchableOpacity>
  );
}

const UserPlate = (props) => {
  const { userRecord } = props;
  const { displayName, photoUrl, screenName } = userRecord;

  const { refreshAuth } = useAuth();
  
  return (
    <View style={userPlateStyles.container}>
      <UserAvatar uri={photoUrl} size="medium"/>
      <View style={userPlateStyles.nameContainer}>
        <Text style={userPlateStyles.name} numberOfLines={1}>{displayName}</Text>
        <Text style={userPlateStyles.nickname} numberOfLines={1}>@{screenName}</Text>
      </View>
      <Icon
        id="logout"
        onPress={async () => {
          await signOut();
          await refreshAuth();
        }}
      />
    </View>
  )
}

export const  DrawerContent = () => {
  const { isAuth, authState , loginUser } = useAuth();

  const { authority } = loginUser || {};

  return (
    <View style={{ flex: 1, paddingVertical: 16 }}>
      <ScrollView style={{ flex: 1 }}>
        <View style={styles.contentContainer}>
          { (() => {
            switch (authState) {
              case AUTH_STATES.SUCCESS:
                return (
                  <UserPlate userRecord={loginUser}/>
                )
              case AUTH_STATES.LOADING:
                return (
                  <View style={styles.loadingContainer}>
                    <LoadingIcon size="small"/>
                    <Text>認証中</Text>
                  </View>
                )
              default:
                return null;
            }
          })() }
          <Divider style={styles.divider}/>
          <MenuItem
            name="profile"
            title="プロフィール"
            iconId="profile"
            isLock={!isAuth}
          />
          <MenuItem
            name="matching"
            title="マッチング"
            iconId="matching"
            isLock={!isAuth}
          />
          <Divider style={styles.divider}/>
          <MenuItem
            name="edit"
            title="エディタ"
            iconId="edit"
            isLock={!isAuth}
          />
          <MenuItem
            name="design"
            title="デザイン"
            iconId="design"
            isLock={!isAuth}
          />
          <MenuItem
            name="description"
            title="募集設定"
            iconId="comment"
            isLock={!isAuth}
          />
          <Divider style={styles.divider}/>
          <MenuItem
            name="database"
            title="デ－タベース"
            iconId="database"
            isLock={!isAuth}
          />
          <Divider style={styles.divider}/>
          <MenuItem
            name="document"
            navigationOption={{ screen: 'top' }}
            title="使い方"
            iconId="document"
            isLock={false}
          />
          <MenuItem
            name="setting"
            title="設定"
            iconId="setting"
            isLock={!isAuth}
          />
          {
            authority >= AUTHORITY.DEVELOPER &&
            <MenuItem
              name="developer"
              title="開発者ツール"
              iconId="developer"
              isLock={!isAuth}
            />
          }
          <Divider style={styles.divider}/>
          { (() => {
            switch (authState) {
              case AUTH_STATES.NOT_LOGGED_IN:
                return (<>
                  <DescriptionText>
                    <Icon id="lock" size={12}/>マークがついている機能はログイン後に利用可能です。
                  </DescriptionText>
                  <TwitterLoginButton
                    containerStyle={styles.buttonContainer}
                  />
                </>)
              case AUTH_STATES.FAILED:
                return (<>
                  <DescriptionText>認証中に問題が発生しました。</DescriptionText>
                  <DescriptionText>再ログインしてください。</DescriptionText>
                  <TwitterLoginButton
                    containerStyle={styles.buttonContainer}
                  />
                </>)
              default:
                return null;
            }
          })() }
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 20,
  },
  headerImage: {
    height: 120,
    width: "100%",
    marginBottom: 12,
  },
  divider: {
    marginVertical: 12,
  },
  buttonContainer: {
    marginVertical: 8,
  },
  loadingContainer: {
    alignItems: "center",
  },
});

const userPlateStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  nameContainer: {
    marginHorizontal: 12,
    flex: 1,
  },
  name: {
    fontSize: 18,
    fontWeight: "bold",
  },
  nickname: {
    fontSize: 15,
    color: TEMPLATE_COLORS.H_TEXT,
  },
});

const menuStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 12,
  },
  iconContainer: {
    alignItems: "center",
    width: 40, 
    marginRight: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "300",
  },
  right: {
    position: "absolute",
    right: 12,
    height: "100%",
    justifyContent: "center",
  },
});