import { Linking, ScrollView, StyleSheet } from "react-native"
import { useNavigation } from "@react-navigation/native";
import { Background } from "./Background";
import { Text } from "react-native-elements";
import { AText } from "../atoms/AText";
import { Line } from "../atoms/Line";

export const DocumentTopScreen = () => {
  const navigation = useNavigation();

  return (
    <Background>
      <ScrollView style={styles.scrollView}>
        <Line>
          <Text> - </Text>
          <AText
            onPress={() => Linking.openURL("https://zawazawa.jp/my-ramune/")}
          >
            Wikiページ
          </AText>
        </Line>
        <Line>
          <Text> - </Text>
          <AText
            onPress={() => navigation.navigate('document', { screen: 'termOfUse' })}
          >
            利用規約
          </AText>
        </Line>
        <Line>
          <Text> - </Text>
          <AText
            onPress={() => navigation.navigate('document', { screen: 'privacyPolicy' })}
          >
            プラバシーポリシー
          </AText>
        </Line>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    padding: 8,
  },
});