import { Avatar } from 'react-native-elements';

export const UserAvatar = (props) => {
  const { uri, size } = props

  return (
    <Avatar
      rounded
      size={size}
      source={{ uri }}
    />
  )
}