import { createStackNavigator } from "@react-navigation/stack";
import { TradeListScreen } from "src/components/screens/TradeListScreen";
import { TradeDetailScreen } from "src/components/screens/TradeDetailScreen";
import { TradeAddScreen } from "../screens/TradeAddScreen";

const Stack = createStackNavigator();

export const TradePage = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
      }}
    >
      <Stack.Screen
        name="list"
        component={TradeListScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="detail"
        component={TradeDetailScreen}
      />
      <Stack.Screen
        name="add"
        component={TradeAddScreen}
      />
    </Stack.Navigator>
  );
}