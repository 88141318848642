import { StyleSheet, View } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";
import { Text } from "react-native-elements";
import { Icon } from "../atoms/Icon";
import { useTheme } from "src/utils/ThemeProvider";

const headerRightIconsDefaultProps = {
  style: {
    marginHorizontal: 8,
  },
  color: TEMPLATE_COLORS.INVERSE,
}

export const Header = (props) => {
  const { navigation, route, options } = props;

  const { themeColor } = useTheme();

  const {
    title,
    backgroundColor = themeColor,
    headerLeft,
    headerRightIcons = [],
  } = options;

  return (
    <View
      style={[styles.container, {
        backgroundColor,
      }]}
    >
      { navigation.canGoBack() &&
        <Icon
          id="back"
          color={TEMPLATE_COLORS.INVERSE}
          containerStyle={{ marginHorizontal: 12 }}
          onPress={() => navigation.goBack()}
        />
      }
      { headerLeft }
      <Text style={styles.title} numberOfLines={1}>{ title }</Text>
      <View style={{ flexDirection: "row" }}>
        { headerRightIcons.map((props, index) => <Icon {...headerRightIconsDefaultProps} {...props} key={index}/>) }
      </View>
      <Icon
        id="drawer"
        color={TEMPLATE_COLORS.INVERSE}
        containerStyle={{ marginHorizontal: 12 }}
        onPress={() => navigation.toggleDrawer()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 48,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: TEMPLATE_COLORS.INVERSE,
    fontSize: 18,
    flex: 1,
    marginHorizontal: 12,
  },
});