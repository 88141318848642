import { StyleSheet, View } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";

export const SubmitButtonContainer = (props) => {
  const { children, containerStyle } = props;

  return (
    <View style={[styles.container, containerStyle]}>
      { children }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    backgroundColor: TEMPLATE_COLORS.UI,
  },
});