import { Text, View } from "react-native"

import { RARITY_COLOR_MAP, RARITY_ID_MAP, RARITY_STRING_MAP } from "src/const/rarity"

export const RarityLabel = (props) => {
  const { rarity } = props;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: RARITY_COLOR_MAP[rarity],
        }}
      >
        { RARITY_ID_MAP[rarity] } : {RARITY_STRING_MAP[rarity]}
      </Text>
    </View>
  )
}