export const PrivacyPolicy = `第1条（個人情報の定義）
 - 個人情報とは、個人に関する情報であり、氏名、住所、メールアドレス、電話番号など、特定の個人を識別することができる情報を指します。

第2条（個人情報の収集と利用目的）
 - 提供者は、以下の目的で必要な範囲内で個人情報を収集します：

   - 本サービスの提供
   - お問い合わせへの対応
   - 利用規約違反に対する対応
   - その他、サービス改善及び運営上必要な目的

第3条（個人情報の第三者提供）
 - 提供者は、法令で定められた場合を除き、ユーザーの個人情報を第三者に提供しません。

第4条（個人情報の管理）
- 提供者は、個人情報の正確性を保ち、セキュリティ対策を講じることにより、漏洩、紛失、毀損を防ぎます。

第5条（個人情報の開示・訂正・削除）
- ユーザーは、自身の個人情報について、開示、訂正、削除を求めることができます。その際は、指定の方法に従ってください。

第6条（クッキーとアクセスログ）
- 本サービスは、ユーザーの利用状況を把握するためにクッキーを使用することがあります。クッキーの利用を希望しないユーザーは、ブラウザの設定でクッキーを無効にすることができます。
- 本サービスは、アクセスログを収集します。これには、IPアドレス、ブラウザの種類、リファラーなどの情報が含まれます。これらは統計分析などの目的で利用されます。

第7条（プライバシーポリシーの改定）
- 提供者は、必要に応じて本プライバシーポリシーを改定することがあります。重要な変更がある場合は、適切に通知します。

第8条（お問い合わせ）
- 本プライバシーポリシーに関するお問い合わせは、以下のSNSにてダイレクトメッセージをお願いします：
- Twitter: @0MAD04
`