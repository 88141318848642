import { createContext, useContext, useEffect, useState, useCallback } from "react";

import { supabase } from './supabase';
import { createDecorationRecord, getDecorationRecord } from "./supabase/decorationUtil";
import { ERROR_CODES } from "src/const/errors";
import { SERVICE_URL } from "./linkUtil";
import { deleteAllTradeRecords } from "./supabase/tradeUtils";
import { importRamuneData } from "./compatibleUtil";
import { getFlagRecord, upsertFlagRecord } from "./supabase/flagUtil";

const AuthContext = createContext(null);

export const AUTHORITY = {
  DEVELOPER: 5,
  FREE: 0,
}

export const AUTH_STATES = {
  SUCCESS: "success",
  NOT_LOGGED_IN: "not-logged-in",
  FAILED: "failed",
  LOADING: "loading",
  INITIALIZING: "initializing",
};

const getUserRecordFromDBRecord = (DBRecord) => {
  const {
    user_id,
    display_name,
    screen_name,
    photo_url,
    authority,
  } = DBRecord;

  return {
    userId: user_id,
    displayName: display_name,
    screenName: screen_name,
    photoUrl: photo_url,
    authority,
  };
}

export const signInWithTwitter = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'twitter',
    options: {
      redirectTo: `${SERVICE_URL}`,
      //redirectTo: 'http://192.168.1.43:19006',
    }
  })
  if (error) console.error(error);
  console.log("signInWithTwitter", data);
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) console.error(error);
  console.log("SignOut");
}

const getUserDataOrCreate = async (twitterUserData) => {
  const {
    id: user_id,
    user_metadata,
  } = twitterUserData;
  const {
    name: display_name,
    user_name: screen_name,
    avatar_url: photo_url,
  } = user_metadata;

  const userRecord = {
    user_id,
    display_name,
    screen_name,
    photo_url,
  };

  const { error: upsertError } = await supabase.from('users').upsert(userRecord);
  if ( upsertError ) throw(upsertError);

  const { data, error: selectError } = await supabase.from('users').select("*").eq("user_id", user_id);
  if ( selectError ) throw(selectError);

  return getUserRecordFromDBRecord(data[0]);
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const { children } = props;
  
  const [ loginUser, setLoginUser ] = useState(null); 
  const [ authState, setAuthState ] = useState(AUTH_STATES.LOADING); 

  const refreshAuth = useCallback(async () => {
    try {
      const userResult = await supabase.auth.getUser();
      const _user = userResult.data.user;

      if (!_user) throw 'not-login'

      const loginUserRecord = await getUserDataOrCreate(_user);

      console.log("AuthProvider::login::success", loginUserRecord)

      setAuthState(AUTH_STATES.SUCCESS);
      setLoginUser(loginUserRecord);
    } catch (error) {
      switch(error){
        case "not-login":
          console.log("AuthProvider::login::not-login", error);
          setAuthState(AUTH_STATES.NOT_LOGGED_IN);
          break
        default:
          console.error("AuthProvider::login::error", error);
          setAuthState(AUTH_STATES.FAILED);
      }
    }
  }, [])

  useEffect(() => {
    refreshAuth();
  }, []);

  useEffect(() => initialize(), [loginUser])

  const initialize = useCallback(() => {
    if (!loginUser) return;
    if (authState !== AUTH_STATES.SUCCESS) return;

    const { userId } = loginUser;

    (async () => {
      setAuthState(AUTH_STATES.INITIALIZING);

      // デコレーションデータ存在チェック
      try {
        await getDecorationRecord(userId)
      } catch (error) {
        if (error.errorCode === ERROR_CODES.NO_DECORATION_DATA.errorCode){
          await createDecorationRecord({
            userId,
            comment: "よろしくお願いします✨",
            themeColorId: 'blue',
          })
        }
      }

      let isInitialCompatibleImport = false;
      let flagRecord;
      try {
        flagRecord = await getFlagRecord(userId)
        isInitialCompatibleImport = !!flagRecord.isInitialCompatibleImport;
      } catch (error) {
      }

      if(!isInitialCompatibleImport){
        console.log("AuthProvider::initial::compatibleImport");
        await deleteAllTradeRecords(userId);
        await importRamuneData(loginUser);
        upsertFlagRecord({
          ...flagRecord,
          userId,
          isInitialCompatibleImport: true,
        })
      }

      setAuthState(AUTH_STATES.SUCCESS);
    })();

    
  }, [loginUser]);

  const value = {
    authState,
    loginUser,
    loginUserId: loginUser ? loginUser.userId : undefined,
    isLoading: authState === AUTH_STATES.LOADING,
    isInitializing: authState === AUTH_STATES.INITIALIZING,
    isAuth: authState === AUTH_STATES.SUCCESS,
    isFailed: authState === AUTH_STATES.FAILED,
    isNotLogged: authState === AUTH_STATES.NOT_LOGGED_IN,
    refreshAuth,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}