import { View } from 'react-native';
import { TEMPLATE_COLORS } from 'src/const/colors';

export const Background = (props) => {
  const { children, containerStyle } = props;

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: TEMPLATE_COLORS.BACKGROUND,
        ...containerStyle,
      }}
    >
      {children}
    </View>
  )
}