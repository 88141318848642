import { ScrollView, StyleSheet, View } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";

import { RarityIcon } from "../molecules/RarityIcon";
import { ItemList } from "../molecules/ItemList";

const Divider = (props) => {
  const { color } = props;
  return (
    <View style={[styles.divider, {
      backgroundColor: color,
    }]}/>
  )
}

export const TradesCard = (props) => {
  const {
    tradeRecords,
  } = props;

  return (
    <View style={{ width: "100%" }}>
      {
        [5, 4, 3, 2, 1].map((rarity) => {
          const itemList = tradeRecords.filter(record => record.rarity === rarity);

          if (!itemList.length) return null;

          return (
            <View style={styles.rowContainer} key={rarity}>
              <View style={ styles.row }>
                <View style={styles.rarityColumn} key={rarity}>
                  <RarityIcon
                    rarity={rarity}
                    isSelected={false}
                    isTextEnabled={false}
                    isBorderEnabled={false}
                  />
                </View>
                <View style={styles.column}>
                  <ItemList itemList={itemList} isWrap={true} size={52} key={rarity}/>
                </View>
              </View>
              <Divider/>
            </View>
          )
        })
      }
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    alignItems: "center",
  },
  row: {
    marginVertical: 4,
    width: "100%",
    flexDirection: "row",
  },
  column: {
    flex: 1,
  },
  rarityColumn: {
    width: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    width: "90%",
    height: 1,
    backgroundColor: TEMPLATE_COLORS.BORDER,
  }
});