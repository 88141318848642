import { TEMPLATE_COLORS } from "./colors";

export const TRADE_STATUS = {
  GIVE: 1, // 譲
  TAKE: 2, // 求
  HOLD: 3, // 保留
};

export const TRADE_STATUS_COLOR_MAP = {
  [TRADE_STATUS.GIVE]: TEMPLATE_COLORS.GIVE,
  [TRADE_STATUS.TAKE]: TEMPLATE_COLORS.TAKE,
  [TRADE_STATUS.HOLD]: TEMPLATE_COLORS.HOLD,
}

export const TRADE_STATUS_ICON_ID_MAP = {
  [TRADE_STATUS.GIVE]: "give",
  [TRADE_STATUS.TAKE]: "take",
  [TRADE_STATUS.HOLD]: "hold",
}

export const TRADE_STATUS_URL_MAP = {
  [TRADE_STATUS.GIVE]: "give",
  [TRADE_STATUS.TAKE]: "take",
  [TRADE_STATUS.HOLD]: "hold",
}

export const TRADE_STATUS_STRING_MAP = {
  [TRADE_STATUS.GIVE]: "譲る",
  [TRADE_STATUS.TAKE]: "求む",
  [TRADE_STATUS.HOLD]: "所持",
}

export const TRADE_STATUS_DESCRIPTION_MAP = {
  [TRADE_STATUS.GIVE]: "譲りたいもの",
  [TRADE_STATUS.TAKE]: "欲しいもの",
  [TRADE_STATUS.HOLD]: "持っているもの",
}