import { StyleSheet, Text, TouchableOpacity } from "react-native";

export const AText = (props) => {
  const {
    style,
    children,
    onPress,
  } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <Text
        {...props}
        style={[styles.text, style]}
      >
        { children }
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  text: {
    textDecorationLine: 'underline',
  },
});