import { supabase } from ".";

const cleanRecord = (record) => {
  const rtn = {};
  for (const [key, value] of Object.entries(record)) {
    if(value != null) rtn[key] = value;
  }
  return rtn;
}

const getFlagRecordFromDBRecord = (DBRecord) =>{
  const {
    user_id,
    is_initial_compatible_import,
  } = DBRecord;

  return {
    userId: user_id,
    isInitialCompatibleImport: is_initial_compatible_import,
  }
}

const getDBRecordFromFlagRecord = (DBRecord) =>{
  const {
    userId,
    isInitialCompatibleImport,
  } = DBRecord;

  return {
    user_id: userId,
    is_initial_compatible_import: isInitialCompatibleImport,
  }
}

export const getFlagRecord = async (userId) => {
  const { data, error } = await supabase.from("flags").select('*').eq("user_id", userId);
  if(error) throw error;

  const record = data[0];
  return getFlagRecordFromDBRecord(record);
}

export const createFlagRecord = async (flagRecord) => {
  const { error } = await supabase.from("flags").insert(getDBRecordFromFlagRecord(flagRecord));
  if(error) throw error;
}

export const updateFlagRecord = async (flagRecord) => {
  const DBRecord = cleanRecord(getDBRecordFromFlagRecord(flagRecord));

  const { user_id } = DBRecord;

  const { error } = await supabase.from("flags").update(DBRecord).eq("user_id", user_id);
  if(error) throw error;
}

export const upsertFlagRecord = async (flagRecord) => {
  const DBRecord = cleanRecord(getDBRecordFromFlagRecord(flagRecord));

  const { user_id } = DBRecord;

  const { error } = await supabase.from("flags").upsert(DBRecord).eq("user_id", user_id);
  if(error) throw error;
}