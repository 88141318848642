import { StyleSheet, View } from "react-native"
import { Icon } from "../atoms/Icon";
import { TEMPLATE_COLORS } from "src/const/colors";

export const TradableCardHeader = ()=> {
  return (
    <View style={styles.headerContainer}>
      <View style={[styles.headerCell, {
        borderColor: TEMPLATE_COLORS.GIVE,
      }]}>
        <Icon id="give"/>
      </View>
      <View style={[styles.headerCell, {
        borderColor: TEMPLATE_COLORS.TAKE,
      }]}>
        <Icon id="take"/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 12,
    paddingTop: 8,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
  },
  headerCell: {
    flex: 0.4,
    justifyContent: "center",
    alignItems: "center",
    height: 32,
    borderBottomWidth: 2,
    paddingBottom: 12,
  },
});