import { TEMPLATE_COLORS } from "./colors";

export const RARITY_ID_MAP = {
  5: "S",
  4: "A",
  3: "B",
  2: "C",
  1: "D",
}

export const RARITY_COLOR_MAP = {
  5: TEMPLATE_COLORS.RARITY5,
  4: TEMPLATE_COLORS.RARITY4,
  3: TEMPLATE_COLORS.RARITY3,
  2: TEMPLATE_COLORS.RARITY2,
  1: TEMPLATE_COLORS.RARITY1,
};

export const RARITY_STRING_MAP = {
  5: "レジェンド",
  4: "エピック",
  3: "レア",
  2: "アンコモン", 
  1: "コモン",
}

export const RARITY_DESCRIPTION_MAP = {
  5: "うらやましがられる",
  4: "手に入ると嬉しい",
  3: "あまり手に入らない",
  2: "嬉しくも悲しくもない", 
  1: "余るくらい手に入る",
}