import { useEffect, useState } from "react"
import { ScrollView } from "react-native-gesture-handler";

import { Background } from './Background';

import { getItemRecord } from "src/utils/supabase/itemUtil";
import { useNavigation } from "@react-navigation/native";
import { SubmitButtonContainer } from "../atoms/SubmitButtonContainer";
import { Button } from "../atoms/Button";
import { InfoContainer } from "../atoms/InfoContainer";
import { View } from "react-native";
import { ItemImage } from "../molecules/ItemImage";
import { Text } from "react-native-elements";
import { TagContainer } from "../molecules/TagContainer";
import { RarityLabel } from "../molecules/RarityLabel";
import { useLoading } from "src/utils/LoadingProvider";

export const ItemDetailScreen = (props) => {
  const { route } = props;
  const { params } = route;
  const { itemId } = params;

  const navigation = useNavigation()

  const [ itemRecord, setItemRecord ] = useState(null);

  useEffect(() => {
    (async () => {
      const _itemRecord = await getItemRecord(itemId);
      setItemRecord(_itemRecord);
    })();
  }, [])

  useEffect(() => {
    if (!itemRecord) return;

    navigation.setOptions({
      headerTitle: itemRecord.itemName,
    });
  }, [itemRecord])

    const { setLoading } = useLoading();
  useEffect(() => setLoading({ 'ItemDetailScreen::itemRecords': !itemRecord }), [itemRecord]);

  if(!itemRecord) return;

  const {
    itemName,
    itemImageFilePath,
    defaultRarity,
    tags,
    adminUserId,
  } = itemRecord;

  return (
    <Background>
      <ScrollView>
        <></>
        <InfoContainer title="アイテム画像">
          <View style={{alignItems: "center"}}>
            <ItemImage itemImageFilePath={itemImageFilePath} size={128}/>
          </View>
        </InfoContainer>
        <InfoContainer title="アイテム名">
          <Text>{itemName}</Text>
        </InfoContainer>
        <InfoContainer title="タグ">
          <TagContainer tags={tags}/>
        </InfoContainer>
        <InfoContainer title="デフォルトレアリティ">
          <RarityLabel rarity={defaultRarity}/>
        </InfoContainer>
        {/* <InfoContainer title="管理者">
          <Text>{adminUserId}</Text>
        </InfoContainer> */}
      </ScrollView>
      <SubmitButtonContainer>
        <Button title="編集する" disabled={true}/>
      </SubmitButtonContainer>
    </Background>
  );
}