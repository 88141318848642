import { StyleSheet, TouchableOpacity, View } from "react-native"
import { TEMPLATE_THEME_COLORS } from "src/const/template"
import { Icon } from "../atoms/Icon"
import { TEMPLATE_COLORS } from "src/const/colors"

export const ThemeColorPicker = (props) => {
  const { colorId, setColorId } = props;

  return (
    <View style={styles.container}>
      {
        Object.entries(TEMPLATE_THEME_COLORS).map(([themeColorId, themeColor]) => {
          return (
            <TouchableOpacity
              style={[styles.button, {
                backgroundColor: themeColor,
              }]}
              onPress={() => {
                setColorId(themeColorId);
              }}
              key={themeColorId}
            >
              { colorId === themeColorId &&
                <Icon id="check" color={TEMPLATE_COLORS.INVERSE}/>
              }
            </TouchableOpacity>
          )
        })
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  button: {
    width: 32,
    height: 32,
    borderRadius: 2,
    margin: 4,
    justifyContent: "center",
    alignItems: "center",
  },
})