import { StyleSheet, TouchableOpacity } from "react-native";
import { MARKER_COLORS } from "src/const/marker";

export const MarkerButton = (props) => {
  const {
    markerId,
    size,
    onPress,
    isSelected,
  } = props;

  const markerColor = MARKER_COLORS[markerId];

  return (
    <TouchableOpacity
      style={[styles.button, {
        width: size,
        height: size,
        borderColor: markerColor,
        backgroundColor: isSelected && markerColor,
      }]}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    borderWidth: 4,
    marginHorizontal: 4,
  }
});