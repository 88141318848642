import { ScrollView, StyleSheet, View } from "react-native"
import { Background } from "../screens/Background"
import { ListItem, Text } from "react-native-elements"
import { useCallback, useEffect, useState } from "react"
import { useAuth } from "src/utils/AuthProvider"
import { getUserRecord } from "src/utils/supabase/userUtil"
import { importRamuneData } from "src/utils/compatibleUtil"
import { deleteAllTradeRecords } from "src/utils/supabase/tradeUtils"
import { TEMPLATE_COLORS } from "src/const/colors"
import { DangerButton } from "../molecules/DangerButton"
import { InfoContainer } from "../atoms/InfoContainer"
import { useNavigation } from "@react-navigation/native"
import { useLoading } from "src/utils/LoadingProvider"

export const AccordionListItem = (props) => {
  const { children, title, depth=0 } = props;

  const [ isExpanded, setIsExpanded ] = useState(false);

  return (
    <ListItem.Accordion
      content={
        <ListItem.Content style={{ marginLeft: depth*16 }}>
          <ListItem.Title>{ title }</ListItem.Title>
        </ListItem.Content>
      }
      isExpanded={isExpanded}
      onPress={() => setIsExpanded(!isExpanded)}
      topDivider
      bottomDivider
      {...props}
    >
      { children }
    </ListItem.Accordion>
  )
}

export const SettingPage = (props) => {

  const { loginUser} = useAuth();
  const { setLoading } = useLoading()

  const navigation = useNavigation();

  const exit = useCallback(() => {
    navigation.navigate("top");
  }, []);

  if(!loginUser) return null;

  return (
    <Background>
      <ScrollView>
        <AccordionListItem title="データ">
          <AccordionListItem title="インポート" depth={1}>
            <InfoContainer title="旧マイラムネから移行">
              <View style={styles.description}>
                <Text>旧マイラムネからデータをインポートします。</Text>
                <Text>現在のデータは削除されます。</Text>
              </View>
              <DangerButton
                title="インポート"
                onPress={async () =>  {
                  setLoading({ 'SettingPage::data::import': true });
                  await deleteAllTradeRecords(loginUser.userId);
                  await importRamuneData(loginUser);
                  setLoading({ 'SettingPage::data::import': false });
                  exit();
                }}
              />
            </InfoContainer>
          </AccordionListItem>
          <AccordionListItem title="削除" depth={1}>
            <InfoContainer title="全トレードデータ削除">
              <View style={styles.description}>
                <Text>全ての交換情報を削除します。</Text>
              </View>
              <DangerButton
                title="削除"
                onPress={async () => { 
                  setLoading({ 'SettingPage::data::delete': true });
                  await deleteAllTradeRecords(loginUser.userId);
                  setLoading({ 'SettingPage::data::delete': false });
                  exit();
                }}
                buttonStyle={{
                  backgroundColor: TEMPLATE_COLORS.DANGER,
                }}
              />
            </InfoContainer>
          </AccordionListItem>
        </AccordionListItem>
      </ScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  description: {
    marginVertical: 16,
  },
});