import { Animated, Easing, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { Icon } from "../atoms/Icon"
import { TEMPLATE_COLORS } from "src/const/colors"
import { useEffect, useRef, useState } from "react";
import { Swipeable } from "react-native-gesture-handler";

const SwipeableBox = (props) => {
  return (
    <View style={styles.swipeableBox}/>
  )
}

export const DeleteIndicator = (props) => {
  const {
    isDeleted,
    setIsDeleted,
    isDeleting,
    onDelete,
    onUndo,
  } = props;

  const anim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isDeleting) {
      Animated.timing(anim, {
        toValue: 1,
        duration: 400,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: true,
      }).start(({ finished }) => {
        if(finished){
          onDelete();
          setIsDeleted(true);
        }
      });
    } else {
      anim.setValue(0);
    }
  }, [isDeleting]);

  const height = 
    isDeleted
      ? "100%"
      : anim.interpolate({
          inputRange: [0, 1],
          outputRange: ["0%", "100%"],
        });

  if (!isDeleting && !isDeleted) return null;

  return (
    <Swipeable
      containerStyle={styles.area}
      childrenContainerStyle={styles.container}
      renderLeftActions={(progress, dragX) => <SwipeableBox/>}
      renderRightActions={(progress, dragX) => <SwipeableBox/>}
      onSwipeableOpen={(direction) => {
        onUndo();
        setIsDeleted(false);
      }}
    >
      <Animated.View style={[styles.deleteIndicator, { height }]}/>
      { isDeleted ? <Icon id="caretLeft" color={TEMPLATE_COLORS.INVERSE} containerStyle={styles.caret} size={8}/> : <View/>}
      <View>
        <Icon id="delete" color={TEMPLATE_COLORS.INVERSE}/>
        <Text style={{ color: TEMPLATE_COLORS.INVERSE }}>削除</Text>
      </View>
      { isDeleted ? <Icon id="caretRight" color={TEMPLATE_COLORS.INVERSE} containerStyle={styles.caret} size={8}/> : <View/>}
    </Swipeable>
  )
}

const styles = StyleSheet.create({
  area: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIndicator: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: TEMPLATE_COLORS.DANGER,
    zIndex: -100,
  },
  swipeableBox: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.8,
    backgroundColor: TEMPLATE_COLORS.CARD_BACKGROUND,
  },
  caret: {
    marginHorizontal: 8,
  },
})