import { StyleSheet } from "react-native";
import { Text } from "react-native-elements";

export const DescriptionText = (props) => {

  const _props = {...props};
  _props.style = {
    ...styles.description,
    ...props.style,
  }

  return (
    <Text
      {..._props}
    />
  )
}

const styles = StyleSheet.create({
  description: {
    letterSpacing: 3,
  },
});
