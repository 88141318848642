import { useState } from 'react';

import {
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

import { MatchingNameCard } from './MatchingNameCard';
import { TradableCard } from './TradableCard';
import { MatchingCommentCard } from './MatchingCommentCard';
import { getTemplateThemeColor } from 'src/const/template';
import { BalloonContainer } from '../atoms/BalloonContainer';
import { useNavigation } from '@react-navigation/native';
import { InverseContainer } from '../atoms/InverseContainer';
import { TradableCardHeader } from '../molecules/TradableCardHeader';

// if (Platform.OS === 'android') {
//   if (UIManager.setLayoutAnimationEnabledExperimental) {
//     UIManager.setLayoutAnimationEnabledExperimental(true);
//   }
// }

const OPEN_MODE = {
  TRADE: "trade",
  COMMENT: "comment",
  PROFILE: "profile",
};

export const MatchingCard = (props) => {
  const {
    giveTradeIds = [],
    takeTradeIds = [],
    width,
    height,
    userRecord,
    decorationRecord,
  } = props;

  const [ openMode, setOpenMode ] = useState(OPEN_MODE.TRADE);

  const navigation = useNavigation();

  return (
    <View
      style={{
        width,
        height,
      }}
    >
      <MatchingNameCard
        userRecord={userRecord}
        onPress={() => {
          navigation.navigate("profile", {
            userId: userRecord.userId,
          });
        }}
      />
      <BalloonContainer
        containerStyle={{ flex: 1 }}
        contentStyle={{ paddingBottom: 8 }}
        color={getTemplateThemeColor(decorationRecord?.themeColorId)}
      >
        <MatchingCommentCard
          comment={decorationRecord?.comment}
          isOpen={openMode === OPEN_MODE.COMMENT}
          onPress={() => {
            const _openMode = openMode !== OPEN_MODE.COMMENT ? OPEN_MODE.COMMENT : OPEN_MODE.TRADE
            setOpenMode(_openMode);
          }}
        />
        <InverseContainer containerStyle={{ flex: 1 }}>
          <ScrollView
            style={styles.tradableCardContainer}
            stickyHeaderIndices={[0]}
          >
            <TradableCardHeader/>
            <TradableCard
              giveTradeIds={giveTradeIds}
              takeTradeIds={takeTradeIds}
              isShowHeader={false}
            />
          </ScrollView>
        </InverseContainer>
      </BalloonContainer>
    </View>
  )
};

const styles = StyleSheet.create({
  tradableCardContainer: {
    flex: 1,
    width: "100%",
  },
});