import { useState, useEffect } from "react";
import { Text, Input, CheckBox } from "react-native-elements"
import * as ExpoDocumentPicker from 'expo-document-picker';

import { InputContainer } from "../atoms/InputContainer";
import { AUTHORITY, useAuth } from "src/utils/AuthProvider";

import { registerItem, cleanItem } from "src/utils/supabase/itemUtil";
import { supabase } from "src/utils/supabase";
import { registerTradeRecord } from "src/utils/supabase/tradeUtils";
import { TEMPLATE_COLORS } from "src/const/colors";
import { TRADE_STATUS } from "src/const/trade";
import { Background } from "../screens/Background";
import { DangerButton } from "../molecules/DangerButton";
import { Button } from "../atoms/Button";

const documentPickerOptions= {
  multiple: true,
};

const registerItemBulk = async (adminUserId, dataA, dataB) => {
  try{
    console.log("registerItemBulk::start");
    const itemDataURL = Object.values(dataA)[0];

    const itemData = await fetch(itemDataURL).then( response => response.json())

    console.log("itemCount", itemData.length)
    for (const itemRecord of itemData){
      const {
        deprecatedId,
        itemName,
        filePath,
        defaultRarity,
        tags,
      } = itemRecord;

      const itemImageUri = dataB[filePath]
      const itemImage = await fetch(itemImageUri).then(string => string.blob())
      
      if (!filePath || !itemImageUri || !itemImage) {
        console.error("No image file!", {filePath, itemImage});
        continue;
      }

      const params = {
        deprecatedId,
        itemName,
        itemImage,
        defaultRarity,
        tags,
        adminUserId,
      }

      registerItem(params)
    }
    console.log("registerItemBulk finished");
  } catch(error) {
    console.log(error);
  }
}

const generateRandomTradeData = async (userId, sampleCount) => {
  console.log("generateRandomTradeData start")
  try {    
    const { data: items, error } = await supabase.from('items').select('item_id')
    if(error) throw(error);

    const itemIds = items.map(record => record.item_id);

    for (let i=0; i<sampleCount; i++){

      const tradeStatus = [TRADE_STATUS.GIVE, TRADE_STATUS.TAKE, TRADE_STATUS.HOLD][Math.floor(Math.random()*3)];
      const rarity = 1 + Math.floor(Math.random()*5)
      const itemId = itemIds[Math.floor(Math.random()*itemIds.length)]

      const { error } = await registerTradeRecord({
        userId,
        itemId,
        rarity,
        tradeStatus,
      })
      if(error) throw(error);
    }
    console.log("generateRandomTradeData finish");
  } catch(error) {
    console.error(error)
  }
}

const readAsData = async (files, setData) => {
  try {
    const _data = {};
    for (const file of files) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      const data = await new Promise(resolve => fileReader.onload = () => resolve(fileReader.result));
      _data[file.name] = data;
    }
    setData(_data);
  } catch (error) {
    console.error(error);
  }
}

const DataInput = (props) => {
  const {
    title,
    setFiles,
    files,
    data,
  } = props;

  return (
    <InputContainer title={title}>
      <Text>読み込み完了：{Object.keys(data).length}/{files.length}</Text>
      <Button
        title="選択"
        onPress={async () => {
          const pickerResult = await ExpoDocumentPicker.getDocumentAsync(documentPickerOptions); 
          const { output } = pickerResult;
          setFiles(output);
        }}
      />
    </InputContainer>
  );
}

const getDataAvailable = (files, data) => {
  const dataLength = Object.keys(data).length;
  return (
    dataLength > 0
    && dataLength === files.length
  )
}

export const DeveloperPage = () => {

  const [ filesA, setFilesA ] = useState([]);
  const [ dataA, setDataA ] = useState({});

  const [ filesB, setFilesB ] = useState([]);
  const [ dataB, setDataB ] = useState({}); 
  
  const [ text, setText ] = useState("");

  useEffect(() => {
    readAsData(filesA, setDataA);
  }, [filesA])

  useEffect(() => {
    readAsData(filesB, setDataB);
  }, [filesB])


  const { loginUser } = useAuth();
  if(!loginUser) return;

  const { authority, userId } = loginUser;
  if(!authority) return;
  if(authority < AUTHORITY.DEVELOPER) return;

  return (
    <Background>
      <DataInput
        title="DataA"
        setFiles={setFilesA}
        files={filesA}
        data={dataA}
      />
      <DataInput
        title="DataB"
        setFiles={setFilesB}
        files={filesB}
        data={dataB}
      />
      <InputContainer title="TextA">
        <Input
          value={text}
          onChangeText={(value) => { setText(value); }}
        />
      </InputContainer>
      <InputContainer title="アイテム一括登録">
        <Text>アイテムデータを一括で登録します。</Text>
        <Text>アイテムJSON:DataA</Text>
        <Text>アイテムの画像（複数）:DataB</Text>
        <Button
          title="実行"
          onPress={() => {
            registerItemBulk(userId, dataA, dataB);
          }}
          disabled={!getDataAvailable(filesA, dataA) || !getDataAvailable(filesB, dataB)}
        />
      </InputContainer>
      <InputContainer title="アイテム削除">
        <Text>アイテムを削除します。</Text>
        <Text>itemId:TextA</Text>
        <Button
          title="実行"
          onPress={() => {
            cleanItem(text);
          }}
          disabled={!text}
        />
      </InputContainer>
      <InputContainer title="サンプルトレードアイテム生成">
        <Text>あるユーザーに対してランダムなトレードアイテムを100件生成します。</Text>
        <Text>userId:TextA</Text>
        <Button
          title="実行"
          onPress={() => {
            generateRandomTradeData(text, 100);
          }}
          disabled={!text}
        />
      </InputContainer>
      <InputContainer title="全アイテムデータ一括削除">
        <DangerButton
          title="実行"
          onPress={async () => {
            console.log("Start Delete All Data");
            console.log("- Delete All Item Data");
            const { error: itemDeleteError } = await supabase.from("items").delete().neq('name', '') // アイテムデータの削除
            if(itemDeleteError) console.error(itemDeleteError);
        
            console.log("- Delete All Item Image");
            const { error: imageDeleteError } = await supabase.storage.emptyBucket("item-images") // 画像の削除
            if (imageDeleteError) console.error(imageDeleteError);
            console.log("Finished Delete All Data");
          }}
        />
      </InputContainer>
    </Background>
  )
}