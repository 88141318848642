import { useState } from "react";

import {
  View,
  ScrollView,
} from "react-native";
import {
  Input,
  Button,
  Text,
} from "react-native-elements";

import { Background } from './Background';

import { ItemImagePicker } from "../molecules/ItemImagePicker";
import { InputContainer } from "../atoms/InputContainer";

import { TEMPLATE_COLORS } from "src/const/colors";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import { registerItem } from "src/utils/supabase/itemUtil";
import { useAuth } from "src/utils/AuthProvider";
import { SubmitButtonContainer } from "../atoms/SubmitButtonContainer";
import { RarityRadioButton } from "../molecules/RarityRadioButton";

export const ItemCreateScreen = (props) => {
  const { navigation } = props;



  const [ itemImage, setItemImage ] = useState(null);
  const [ itemName, setItemName ] = useState("");
  const [ defaultRarity, setDefaultRarity ] = useState(3);
  
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const { loginUser } = useAuth();

  const ErrorMessage = (message) => {
    if (!message) return;

    return (
      <Text style={{
        color: TEMPLATE_COLORS.ERROR,
      }}>{message}</Text>
    );
  };

  const onSubmit = () => {
    navigation.goBack();
  }

  return (
    <Background>
      <ScrollView>
        <InputContainer title="アイテム画像">
          { (isSubmitted && !itemImage) && ErrorMessage("アイテム画像は必須です。") }
          <View style={{alignItems: "center"}}>
            <ItemImagePicker
              image={itemImage}
              setImage={setItemImage}
              size={48}
            />
          </View>
        </InputContainer>
        <InputContainer title="アイテム名">
          { (isSubmitted && !itemName) && ErrorMessage("アイテム名は必須です。") }
          <Input
            placeholder="アイテム名"
            maxLength={40}
            containerStyle={{ flex:1, marginLeft: 8 }}
            value={itemName}
            onChangeText={(text) => { setItemName(text); }}
          />
        </InputContainer>
        <InputContainer title="レアリティ">
          <RarityRadioButton
            rarity={defaultRarity}
            setRarity={setDefaultRarity}
          />
        </InputContainer>
        <TouchableWithoutFeedback
            onPress={async () => {
              setIsSubmitted(true);
              setIsSubmitting(true);
              const isSuccess = await registerItem({
                itemName,
                itemImage,
                defaultRarity,
                adminUserId: loginUser.userId,
              });
              if (isSuccess) onSubmit();
              setIsSubmitting(false);
            }}
        >
        </TouchableWithoutFeedback>
      </ScrollView>
      <SubmitButtonContainer>
        <Button
          title="登録"
          disabled={!itemImage || !itemName}
          loading={isSubmitting}
        />
      </SubmitButtonContainer>
    </Background>
  );
}
