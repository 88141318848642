export const TermOfUse = `
第1条（適用）
 - 本規約は、本サービスの個人提供者「おまど」（以下「当方」という）が提供する物々交換Webサービス「マイラムネ+」（以下「本サービス」という）の利用条件を定めるものです。
 - 本サービスを利用するユーザー（以下「ユーザー」という）は、本規約に同意したものとみなされます。

第2条（サービス内容）
 - 本サービスは、物品を交換するためのプラットフォームを提供します。
 - ユーザーは、本サービスが提供するデータベースから欲しい物品と譲渡可能な物品を選び、登録することができます。
 - 本サービスを通じて、交換可能なユーザー同士がマッチングすることができます。
 - 本サービスでは、ユーザー同士の直接的な連絡は禁止されており、交換は外部SNS等を通じて行うものとします。

第3条（登録物品に関する規則）
 - ユーザーは、新しく実在する物品を登録したり、既存の物品の基本情報を編集することができます。
 - 登録される物品は、法令に違反するものであってはなりません。また、物々交換という目的に即した物品でなくてはなりません。
 - 新規に物品を登録したユーザーはその物品データの管理者となり、責任を負うものとします。
 - 当方は、登録された物品が不適切であると判断した場合、ユーザーに通知することなく、登録を削除することができるものとします。

第4条（プライバシーと個人情報保護）
 - 当方は、ユーザーのプライバシーと個人情報の取り扱いに細心の注意を払います。
 - 個人情報の取り扱いについては、別途プライバシーポリシーにて定めます。

第5条（変更および終了）
 - 当方は、本サービスの内容を予告なく変更、縮小、または終了することができるものとします。この場合、当方はユーザーに対して一切の責任を負わないものとします。
 - 当方は、サービスのURLや利用規約など、本サービスに関連する事項を予告なしに変更する場合があります。

第6条（提供者の責任制限・免責事項）
 - 提供者は、個人での提供であるため、法人におけるような全責任を負うことができません。本サービスの利用によって生じた直接・間接の損害に対して、提供者は責任を負いません。
 - 特にユーザー同士の物品交換において発生した紛争、損害については、当方の責任範囲外とします。
 - ユーザーの活動によって生成されたコンテンツが法的問題に抵触する場合、これはユーザーの責任となります。被害が発生することが想定される場合、第9条「法的問題の報告」に則って速やかに提供者に報告してください。

第7条（知的財産権）
 - 本サービス上のコンテンツ、ロゴ、デザインなど、提供者の知的財産は法的に保護されており、無断での複製、使用、配布は禁止されています。

第8条（紛争解決）
 - 本規約に起因する紛争については、当方の所在地を管轄する裁判所を専属的合意管轄裁判所とします。

第9条（法的問題の報告）
 - 本サービスの内容において法的な問題、違法行為、規約違反等が発見された場合、速やかに提供者へご連絡いただくようお願いします。提供者は、確認後、適切な対処を行います。連絡先は第10条に記載されています。

第10条（連絡先）
 - 本サービスに関する問い合わせは、以下の連絡先にダイレクトメッセージでお願いします：
 - Twitter: @0MAD04

`