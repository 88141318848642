import { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";

import { Icon } from "src/components/atoms/Icon";
import { MARKER_COLORS } from "src/const/marker";

import { supabase } from "src/utils/supabase";

export const ItemImage = (props) => {
  const {
    itemImageFilePath,
    markerId = 0,
    size = 60,
  } = props

  const [ imageURI, setImageURI ] = useState(null);
  const [ isError, setIsError ] = useState(false);


  useEffect(() => {
    if(!itemImageFilePath) return;
    (async () => {
      const { data, error } = await supabase.storage.from('item-images').download(itemImageFilePath)
      if(error){
        console.error(error);
        setIsError(true);
      }

      const uri = URL.createObjectURL(data);

      setImageURI(uri);
    })()
  }, [itemImageFilePath])
  
  if (isError) return <Icon id="noImage" size={size/2}/>;
  if (!imageURI) return null;

  const markerStyle = markerId && {
    borderColor: MARKER_COLORS[markerId],
    borderWidth: 4,
    padding: 1,
    borderRadius: 8,
  }

  return (
    <View style={[ styles.container, markerStyle]}>
      <Image
        {...props}
        style={[styles.image, {
          width: size*2/3,
          height: size,
        }, props.style]}
        source={{ uri: imageURI }}
      />
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    marginVertical: 1,
    marginHorizontal: 2,
  },
  image: {
    justifyContent: 'center',
    borderRadius: 8,
  },
});