import { StyleSheet, View } from "react-native";

export const FilterInputContainer = (props) => {
  const { children } = props;

  return (
    <View style={styles.container}>
      { children }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    marginVertical: 8,
    paddingHorizontal: 8,

    alignItems: 'center',
  }
})