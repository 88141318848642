import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { LoadingIcon } from "src/components/molecules/LoadingIcon";
import { Background } from "src/components/screens/Background";
import { TEMPLATE_COLORS } from "src/const/colors";

const LoadingContext = createContext(null);

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = (props) => {
  const { children } = props;
  
  const [ loadingStates, setLoadingStates ] = useState({});
  const currentLoadingStates = useRef({});

  const setLoading = useCallback((updatedStates) => {
    currentLoadingStates.current = {
      ...currentLoadingStates.current,
      ...updatedStates,
    }
    setLoadingStates(currentLoadingStates.current)
  }, [loadingStates]);

  const tasks = useMemo(() => {
    const _tasks = [];
    for(const task in loadingStates){
      if (loadingStates[task]) _tasks.push(task);
    }
    return _tasks;
  }, [loadingStates]);

  const value = {
    isLoading: Object.values(loadingStates).filter(v => v).length > 0,
    tasks,
    setLoading,
  }

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
}

export const LoadingOverlay = (props) => {
  const { text } = props;

  const { isLoading, tasks } = useLoading();

  if (!isLoading) return null;

  return (
    <Background containerStyle={styles.area}>
      { text && <Text>{text}</Text>}
      <LoadingIcon size="large"/>
      <View style={styles.tasksContainer}>
        <Text style={styles.tasksText}>ローディング中：</Text>
        { tasks.map(task =>
          <Text style={styles.tasksText} key={task}> - {task}</Text>
        )}
      </View>
    </Background>
  )
}

const styles = StyleSheet.create({
  area: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: "center",
    alignItems: "center",
  },
  tasksContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  tasksText: {
    opacity: 0.1,
  },
});