import { TRADE_STATUS } from "src/const/trade";
import { supabase } from "."

const cleanRecord = (record) => {
  const rtn = {};
  for (const [key, value] of Object.entries(record)) {
    if(value != null) rtn[key] = value;
  }
  return rtn;
}

const getTradeRecordFromDBRecord = (DBRecord) => {
  const {
    trade_id,
    item_id,
    rarity,
    status,
    user_id,
    item_name,
    item_image_file_path,
    marker_id,
    tags,
  } = DBRecord;

  return {
    tradeId: trade_id,
    itemId: item_id,
    rarity,
    tradeStatus: status,
    userId: user_id,
    itemName: item_name,
    itemImageFilePath: item_image_file_path,
    markerId: marker_id,
    tags,
  };
}

const getDBRecordFromTradeRecord = (tradeRecord) => {
  const {
    tradeId,
    itemId,
    rarity,
    tradeStatus,
    userId,
    markerId,
  } = tradeRecord;

  return {
    trade_id: tradeId,
    item_id: itemId,
    rarity,
    status: tradeStatus,
    user_id: userId,
    marker_id: markerId,
  };
}

export const registerTradeRecord = async (tradeRecord) => {
  const { error } = await supabase.from('trades').insert(getDBRecordFromTradeRecord(tradeRecord));
  if(error) throw(error);

  return true;
}

export const getTradeRecords = async (params) => {
  const {
    userId,
    itemName = [],
    rarities = [1, 2, 3, 4, 5],
    tags = [],
  } = params;

  let query = supabase.from("trades_view").select('*')

  query = query.eq("user_id", userId);
  query = query.ilike('item_name', `%${itemName}%`)
  query = query.in('rarity', rarities)
  query = query.contains("tags", tags);

  query = query.order("rarity", { ascending: false })
  query = query.order("item_name")

  const { data, error } = await query;
  if(error) throw(error);

  const allTradeRecords = data.map(getTradeRecordFromDBRecord);

  return {
    [TRADE_STATUS.GIVE]: allTradeRecords.filter(record => record.tradeStatus === TRADE_STATUS.GIVE),
    [TRADE_STATUS.TAKE]: allTradeRecords.filter(record => record.tradeStatus === TRADE_STATUS.TAKE),
    [TRADE_STATUS.HOLD]: allTradeRecords.filter(record => record.tradeStatus === TRADE_STATUS.HOLD),
  }
}

export const getTradeRecord = async (tradeId) => {
  const { data, error } = await supabase.from("trades_view").select('*').eq("trade_id", tradeId);
  if(error) throw(error);

  return getTradeRecordFromDBRecord(data[0]);
}

export const getTradeRecordBulk = async (tradeIds) => {
  if (tradeIds.length === 0) return [];

  const { data, error } = await supabase.from("trades_view").select('*').in("trade_id", tradeIds);
  if(error) throw(error);

  return data.map(getTradeRecordFromDBRecord);
}

export const getTradables = async (userId2) => {
  const { data, error } = await supabase.from("score_tradables_view").select('user_id1, gives, takes').eq("user_id2", userId2).order("score", {ascending: false});
  if(error) throw(error);

  return data.map(({ user_id1, gives, takes }) => { return {
    userId: user_id1,
    giveTradeIds: gives,
    takeTradeIds: takes,
  }});
}

export const getTradablesWith = async (userId1, userId2) => {
  const { data: gives, error: giveError } = await supabase.from("tradables_view").select('trade_ids1').eq("user_id1", userId1).eq("user_id2", userId2);
  if(giveError) throw(giveError);

  const { data: takes, error: takeError } = await supabase.from("tradables_view").select('trade_ids2').eq("user_id2", userId1).eq("user_id1", userId2);
  if(takeError) throw(takeError);

  return {
    gives: gives.map(record => record.trade_ids1)[0],
    takes: takes.map(record => record.trade_ids2)[0],
  }
}

export const upsertTradeRecord = async (tradeRecord) => {
  const DBRecord = cleanRecord(getDBRecordFromTradeRecord(tradeRecord));

  const { trade_id } = DBRecord;

  const { error } = await supabase.from("trades").upsert(DBRecord).eq("trade_id", trade_id);
  if(error) throw(error);
}

export const deleteTradeRecord = async (tradeId) => {
  const { error } = await supabase.from("trades").delete().eq("trade_id", tradeId);
  if(error) throw(error);
}

export const deleteAllTradeRecords = async (userId) => {
  const { error } = await supabase.from("trades").delete().eq("user_id", userId).neq('rarity', -1);
  if(error) throw(error);
}

export const checkItemIdExist = (tradeRecords, itemId) => {
  return {
    [TRADE_STATUS.GIVE]: tradeRecords && tradeRecords[TRADE_STATUS.GIVE] && tradeRecords[TRADE_STATUS.GIVE].filter(({ itemId: _itemId }) => _itemId === itemId).length > 0,
    [TRADE_STATUS.TAKE]: tradeRecords && tradeRecords[TRADE_STATUS.TAKE] && tradeRecords[TRADE_STATUS.TAKE].filter(({ itemId: _itemId }) => _itemId === itemId).length > 0,
    [TRADE_STATUS.HOLD]: tradeRecords && tradeRecords[TRADE_STATUS.HOLD] && tradeRecords[TRADE_STATUS.HOLD].filter(({ itemId: _itemId }) => _itemId === itemId).length > 0,
  }
}