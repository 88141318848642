import { StyleSheet, Text, View } from "react-native";
import { TEMPLATE_COLORS } from "src/const/colors";

export const PageIndicator = (props) => {
  const { pageIndex, pageLength, pageColor } = props;

  return (
    <View style={[styles.pageIndicatorArea, {
      backgroundColor: pageColor,
    }]}>
      <Text style={styles.pageIndicatorTextMain}>
        {pageIndex+1}
      </Text>
      <Text style={styles.pageIndicatorTextSub}>
        /{pageLength}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  pageIndicatorArea: {
    position: "absolute",
    bottom: 0,
    right: 0,
    paddingTop: 0,
    paddingBottom: 8,
    paddingHorizontal: 8,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    borderTopLeftRadius: 8,
  },
  pageIndicatorTextMain: {
    color: TEMPLATE_COLORS.INVERSE,
    fontSize: 24,
  },
  pageIndicatorTextSub: {
    color: TEMPLATE_COLORS.INVERSE,
    fontSize: 16,
  },
})