import { ERROR_CODES } from "src/const/errors";
import { supabase } from ".";

const cleanRecord = (record) => {
  const rtn = {};
  for (const [key, value] of Object.entries(record)) {
    if(value != null) rtn[key] = value;
  }
  return rtn;
}


const getDecorationRecordFromDBRecord = (DBRecord) =>{
  const {
    user_id,
    comment,
    theme_color_id,
  } = DBRecord;

  return {
    userId: user_id,
    comment,
    themeColorId: theme_color_id,
  }
}

const getDBRecordFromDecorationRecord = (DBRecord) =>{
  const {
    userId,
    comment,
    themeColorId,
  } = DBRecord;

  return {
    user_id: userId,
    comment,
    theme_color_id: themeColorId,
  }
}

export const getDecorationRecord = async (userId) => {
  const { data, error } = await supabase.from("decorations").select('*').eq("user_id", userId);
  if(error) throw error;

  if(data.length === 0) throw ERROR_CODES.NO_DECORATION_DATA;

  const record = data[0];
  return getDecorationRecordFromDBRecord(record);
}

export const createDecorationRecord = async (decorationRecord) => {
  const { error } = await supabase.from("decorations").insert(getDBRecordFromDecorationRecord(decorationRecord));
  if(error) throw error;
}

export const updateDecorationRecord = async (decorationRecord) => {
  const DBRecord = cleanRecord(getDBRecordFromDecorationRecord(decorationRecord));

  const { user_id } = DBRecord;

  const { error } = await supabase.from("decorations").update(DBRecord).eq("user_id", user_id);
  if(error) throw error;
}