import { supabase } from "."

export const getUserRecordFromDBRecord = (DBRecord) => {
  const {
    user_id,
    screen_name,
    display_name,
    photo_url,
    authority,
  } = DBRecord;

  return {
    userId: user_id,
    screenName: screen_name,
    displayName: display_name,
    photoUrl: photo_url,
    authority: authority,
  };
}

const getDBRecordFromUserRecord = (tradeRecord) => {
  const {
    userId,
    screenName,
    displayName,
    photoUrl,
    authority,
  } = tradeRecord;

  return {
    user_id: userId,
    screen_name: screenName,
    display_name: displayName,
    photo_url: photoUrl,
    authority: authority,
  };
}

export const getUserRecord = async (userId) => {
  const { data, error } = await supabase.from("users").select('*').eq("user_id", userId);
  if(error) throw error;

  if(data.length === 0) return {};
  return getUserRecordFromDBRecord(data[0]);
} 

export const getUserIds = async (loginUserId) => {
  const { data, error } = await supabase.from('users').select('user_id').neq('user_id', loginUserId);
  if(error) console.error(error);

  if(data.length === 0) return [];
  return data.map(record => record.user_id)
}